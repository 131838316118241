import React from "react";
import { useDispatch } from "react-redux";
import { closeModal } from "./modalReducer";
// import bgimg from "../../../assets/gfx/logo_v1_icon_bw.svg";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";

export default function ModalWrapper({
  children,
  size,
  header,
  maxWidth,
  noClose,
}) {
  const dispatch = useDispatch();
  return (
    <>
      <Modal
        isCentered
        scrollBehavior={"inside"}
        size={size}
        isOpen={true}
        closeOnOverlayClick={!noClose}
        onClose={() => dispatch(closeModal())}
      >
        <ModalOverlay />
        <ModalContent
          p={[0, 0]}
          borderRadius='20px'
          // mt={[50, 12]}
          // mx={[0, 3]}
          maxWidth={maxWidth}
          backgroundColor={"modalBg"}
          position={"relative"}
        >
          <ModalHeader>{header}</ModalHeader>
          {noClose !== true && <ModalCloseButton />}
          <ModalBody p={[1, 5]}>{children}</ModalBody>
          <ModalFooter></ModalFooter>
          {/* <Box
            position='absolute'
            overflow='hidden'
            width='100%'
            minHeight='100%'
            height='100%'
            left='0'
            top='0'
            zIndex='1'
            pointerEvents='none'
          >
            <Image
              position='absolute'
              left='20px'
              top='20px'
              src={bgimg}
              width='200%'
              opacity='0.02'
            />
          </Box> */}
        </ModalContent>
      </Modal>
    </>
  );
}
