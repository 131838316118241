import { Form, Formik } from "formik";
import React from "react";
// import ModalWrapper from "../../app/common/modals/ModalWrapper";
import * as Yup from "yup";
import MyTextInput from "../../app/common/form/MyTextInput";
import { Button } from "@chakra-ui/react";
import { useSelector } from "react-redux";
// import { closeModal } from "../../app/common/modals/modalReducer";
import { signInWithEmail } from "../../app/firestore/firebaseService";
import SocialLogin from "./SocialLogin";
import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { Box, HStack, Link, Text } from "@chakra-ui/layout";
import { Stack } from "@chakra-ui/react";
import { Link as ReactLink } from "react-router-dom";

import { useNavigate } from "react-router-dom";

export default function LoginForm() {
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const { prevLocation } = useSelector((state) => state.auth);
  const laststreamerUrl = localStorage.getItem("laststreamerUrl");

  function handleCancel() {
    if (prevLocation) {
      navigate(prevLocation);
    } else {
      navigate(laststreamerUrl ? laststreamerUrl : "/login");
    }
  }

  return (
    // <ModalWrapper size='mini' header='Login'>
    <Formik
      initialValues={{ email: "", password: "" }}
      validationSchema={Yup.object({
        email: Yup.string().required().email(),
        password: Yup.string().required(),
      })}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        try {
          await signInWithEmail(values);
          setSubmitting(false);
          // dispatch(closeModal());
          if (
            navigate &&
            prevLocation &&
            prevLocation.pathname !== "/recoverpassword"
          ) {
            navigate(prevLocation);
          } else {
            navigate(laststreamerUrl ? laststreamerUrl : "/");
          }
        } catch (error) {
          setErrors({ auth: "User not found or wrong password" });
          setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting, isValid, dirty, errors }) => (
        <Stack
          direction={["column", "row"]}
          justifyItems='center'
          alignItems={"center"}
        >
          <Box w={["100%", "45%"]} pr={["0px", "30px"]} h={["auto", "100%"]}>
            <SocialLogin p='30px' />
          </Box>

          <Box
            w={["100%", "10%"]}
            h={["auto", "100%"]}
            textAlign='center'
            pt={["30px", "0px"]}
          >
            <Text fontStyle='italic' color={"passive"}>
              or
            </Text>
          </Box>

          <Stack
            w={["100%", "45%"]}
            pr={["30px", "30px"]}
            pl={["0px", "30px"]}
            borderLeft={["0px", "1px solid rgba(255,255,255,0.2)"]}
            borderTop={["1px solid rgba(255,255,255,0.2)", "0px"]}
          >
            <Form>
              <FormControl>
                <Stack spacing={5}>
                  <MyTextInput
                    name='email'
                    id='email'
                    // placeholder='Email Adress'
                    label='eMail'
                  />
                  <MyTextInput
                    name='password'
                    id='password'
                    // placeholder='Password'
                    type='password'
                    label='Password'
                  />
                  {errors.auth && (
                    <FormLabel
                      position='relative'
                      color='red'
                      style={{ marginBottom: 0 }}
                    >
                      {errors.auth}
                    </FormLabel>
                  )}

                  <HStack>
                    <Button
                      isLoading={isSubmitting}
                      isDisabled={!isValid || !dirty || isSubmitting ? 1 : 0}
                      type='submit'
                      id='id_submit'
                      variant='primary'
                      w={400}
                    >
                      Login
                    </Button>
                    {prevLocation && (
                      <Button
                        onClick={handleCancel}
                        variant='outline'
                        // p={"15px"}
                        w={110}
                      >
                        Cancel
                      </Button>
                    )}
                  </HStack>
                  <Box>
                    <Box textAlign='right' fontSize='xs' mb='20px'>
                      <Link href='/recoverpassword'>Forgot your password?</Link>
                    </Box>
                    <Box color={"passive"} fontStyle='italic'>
                      or
                    </Box>
                    <Button variant='outline' as={ReactLink} to='/register'>
                      Create Account
                    </Button>
                  </Box>
                </Stack>
              </FormControl>
            </Form>
          </Stack>
        </Stack>
      )}
    </Formik>
    // </ModalWrapper>
  );
}
