import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import HomePageContents from "./HomePageContents";

export default function Landing({ props }) {
  const navigate = useNavigate();

  const { prevLocation } = useSelector((state) => state.auth);
  const laststreamerUrl = localStorage.getItem("laststreamerUrl");

  useEffect(() => {
    if (
      laststreamerUrl &&
      prevLocation?.pathname !== laststreamerUrl &&
      prevLocation?.pathname !== "/" &&
      prevLocation?.pathname !== "/streamersettings"
    ) {
      // console.log(prevLocation);
      navigate(laststreamerUrl);
    }
  }, [navigate, laststreamerUrl, prevLocation]);

  return <HomePageContents />;
}
