import { ButtonGroup, Button } from "@chakra-ui/react";
import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import { Box, Flex, Stack, Text } from "@chakra-ui/layout";
import cuid from "cuid";
import React, { useState } from "react";
import { BsTrash } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { useToast } from "@chakra-ui/toast";
import { uploadToFirebaseStorage } from "../../firestore/firebaseService";
import { updateUserProfilePhoto } from "../../firestore/firestoreService";
import { closeModal } from "../modals/modalReducer";
import ModalWrapper from "../modals/ModalWrapper";
import { getFileExtension } from "../util/util";
import PhotoWidgetCropper from "./PhotoWidgetCropper";
import PhotoWidgetDropzone from "./PhotoWidgetDropzone";
import { getDownloadURL } from "firebase/storage";

export default function PhotoUploadWidget({ setEditMode }) {
  const dispatch = useDispatch();
  const toast = useToast();
  const [files, setFiles] = useState([]);
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);

  function handleUploadImage(params) {
    setLoading(true);
    const filename = cuid() + "." + getFileExtension(files[0].name);

    // console.log(image);
    const uploadTask = uploadToFirebaseStorage(image, filename);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // const progress =
        //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        // console.log("Upload is " + progress + "% done");
      },
      (error) => {
        toast({
          title: "Error",
          description: error.message,
          status: "error",
          isClosable: true,
        });
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          // console.log(downloadURL);
          updateUserProfilePhoto(downloadURL, filename)
            .then(() => {
              setLoading(false);
              handleCancelCrop();
              // setEditMode(false);
              dispatch(closeModal());
            })
            .catch((error) => {
              toast({
                title: "Error",
                description: error.message,
                status: "error",
                isClosable: true,
              });
              setLoading(false);
            });
        });
      }
    );
  }

  function handleCancelCrop() {
    setFiles([]);
    setImage(null);
    dispatch(closeModal());
  }

  function handleDeleteCurrent() {
    updateUserProfilePhoto("", "")
      .then(() => {
        dispatch(closeModal());
      })
      .catch((error) => {
        toast({
          title: "Error",
          description: error.message,
          status: "error",
          isClosable: true,
        });
      });
  }

  return (
    <ModalWrapper size='xl' header='Upload Photo'>
      <Stack minHeight={"200px"}>
        <Box>
          {files.length === 0 && <PhotoWidgetDropzone setFiles={setFiles} />}
          {files.length === 0 && (
            <Box textAlign='right' mr='1' mt='10' mb='-10' fontSize='sm'>
              <Button
                leftIcon={<BsTrash />}
                size='sm'
                onClick={() => handleDeleteCurrent()}
              >
                Delete Current
              </Button>
            </Box>
          )}
        </Box>
        <Flex>
          <Box flex='1' paddingRight={1}>
            {files.length > 0 && (
              <Box margin='auto' textAlign='center'>
                <Text>Resize</Text>
                <PhotoWidgetCropper
                  setImage={setImage}
                  imagePreview={files[0].preview}
                />
              </Box>
            )}
          </Box>
          <Box flex='1' textAlign='center'>
            {files.length > 0 && (
              <>
                <Text>Preview</Text>
                <div
                  className='img-preview'
                  style={{
                    width: "100%",
                    margin: "auto",
                    height: "auto",
                    minHeight: 150,
                    minWidth: 150,
                    borderRadius: "100%",
                    overflow: "hidden",
                  }}
                />
              </>
            )}
          </Box>
        </Flex>
        <Box textAlign='center'>
          {files.length > 0 && (
            <ButtonGroup marginTop='10'>
              <Button
                leftIcon={<CheckIcon />}
                variant='solid'
                id='id_submit'
                colorScheme='green'
                _focus={{ boxShadow: "none" }}
                isLoading={loading}
                onClick={handleUploadImage}
              >
                OK
              </Button>
              <Button
                leftIcon={<CloseIcon />}
                variant='outline'
                colorScheme='red'
                _focus={{ boxShadow: "none" }}
                isDisabled={loading}
                onClick={handleCancelCrop}
              >
                Cancel
              </Button>
            </ButtonGroup>
          )}
        </Box>
      </Stack>
    </ModalWrapper>
  );
}
