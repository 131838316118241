import { getAuth, updateProfile } from "firebase/auth";
import firebase from "../config/firebase";
import { deleteFromFirebaseStorage } from "./firebaseService";
import {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  connectFirestoreEmulator,
  deleteDoc,
  doc,
  getCountFromServer,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import { Timestamp } from "firebase/firestore";

const db = getFirestore(firebase);

if (
  window.location.hostname === "localhost" &&
  process.env.REACT_APP_USE_EMULATOR === "true"
) {
  // db.useEmulator("localhost", 8081);
  connectFirestoreEmulator(db, "localhost", 8081);
  // firebase.functions().useEmulator("localhost", 5001);
  // firebase.storage().useEmulator("localhost", 9199);
}

export function dataFromSnapshot(snapshot) {
  if (!snapshot.exists()) return undefined;
  const data = snapshot.data();

  for (const prop in data) {
    if (data.hasOwnProperty(prop)) {
      // if (data[prop] instanceof firebase.firestore.Timestamp) {
      if (data[prop] instanceof Timestamp) {
        // data[prop] = data[prop].toDate();
        data[prop] = data[prop].toDate().toString();
        // console.log(data[prop]);
      }
    }
  }

  return {
    ...data,
    id: snapshot.id,
  };
}

export function fetchEventsFromFirestore(
  filter,
  startDate,
  limit,
  lastDocSnapshot = null
) {
  const user = firebase.auth().currentUser;
  let eventsRef = db
    .collection("events")
    .orderBy("date")
    .startAfter(lastDocSnapshot)
    .limit(limit);
  switch (filter) {
    case "isGoing":
      return eventsRef
        .where("attendeesIds", "array-contains", user.uid)
        .where("date", ">=", startDate);
    case "isHost":
      return eventsRef
        .where("hostUid", "==", user.uid)
        .where("date", ">=", startDate);

    default:
      return eventsRef.where("date", ">=", startDate);
  }
}

export function deleteQuizitemInFirestore(userId, itemIdd) {
  const ref = doc(db, "quizitems", userId, "questions", itemIdd);
  return deleteDoc(ref);

  // Firebase 8 stuff
  // return db
  //   .collection("quizitems")
  //   .doc(userId)
  //   .collection("questions")
  //   .doc(itemIdd)
  //   .delete();
}

export function listenToQuizitemsFromFirestore(userId) {
  const ref = collection(db, "quizitems", userId, "questions");
  const q = query(ref, orderBy("createdAt", "desc"));

  return q;
  // Firebase 8 stuff
  // return db
  //   .collection("quizitems")
  //   .doc(userId)
  //   .collection("questions")
  //   .orderBy("createdAt", "desc");
}

export function listenToEventFromFirestore(eventId) {
  return db.collection("events").doc(eventId);
}

export function addEventToFirestore(event) {
  const user = firebase.auth().currentUser;

  return db.collection("events").add({
    ...event,
    hostUid: user.uid,
    hostedBy: user.displayName,
    hostPhotoURL: user.photoURL || null,
    attendees: firebase.firestore.FieldValue.arrayUnion({
      id: user.uid,
      displayName: user.displayName,
      photoURL: user.photoURL || null,
    }),
    attendeesIds: firebase.firestore.FieldValue.arrayUnion(user.uid),
  });
}

// export function updateEventInFirestore(event) {
//   return db.collection("events").doc(event.id).update(event);
// }

// export function deleteEventInFirestore(eventId) {
//   return db.collection("events").doc(eventId).delete();
// }

// export function cancelEventToggle(event) {
//   return db.collection("events").doc(event.id).update({
//     isCancelled: !event.isCancelled,
//   });
// }

export function setUserProfileDataTwitch(user) {
  const ref = doc(db, "users", user.uid);
  return setDoc(ref, {
    displayName: user.displayName || null,
    email: user.email,
    photoURL: user.photoURL || null,
    createdAt: serverTimestamp(),
  });

  // Firebase 8 stuff
  // return db
  //   .collection("users")
  //   .doc(user.uid)
  //   .set({
  //     displayName: profile.preferred_username || profile.sub,
  //     email: profile.email,
  //     photoURL: profile.picture || null,
  //     createdAt: firebase.firestore.FieldValue.serverTimestamp(),
  //   });
}

export function setUserProfileDataDiscord(user, profile) {
  // return db
  //   .collection("users")
  //   .doc(user.uid)
  //   .set({
  //     displayName: profile.preferred_username || profile.sub,
  //     email: profile.email,
  //     photoURL: profile.picture || null,
  //     createdAt: firebase.firestore.FieldValue.serverTimestamp(),
  //   });
}

export function setUserProfileDataGoogle(user, profile) {
  const ref = doc(db, "users", user.uid);
  return setDoc(ref, {
    displayName: null,
    email: user.email,
    photoURL: null,
    createdAt: serverTimestamp(),
  });

  // Firebase 8 stuff
  // return db.collection("users").doc(user.uid).set({
  //   displayName: null,
  //   email: user.email,
  //   photoURL: null,
  //   createdAt: firebase.firestore.FieldValue.serverTimestamp(),
  // });
}

export function setUserProfileData(user) {
  const ref = doc(db, "users", user.uid);
  return setDoc(ref, {
    displayName: user.displayName,
    // email: user.email, // Dont save email as it might be public available
    photoURL: user.photoURL || null,
    createdAt: serverTimestamp(),
  });

  // Firebase 8 stuff
  // return db
  //   .collection("users")
  //   .doc(user.uid)
  //   .set({
  //     displayName: user.displayName,
  //     email: user.email,
  //     photoURL: user.photoURL || null,
  //     createdAt: firebase.firestore.FieldValue.serverTimestamp(),
  //   });
}

export function setUserFirstStreamer(streamerUid) {
  const auth = getAuth();
  const user = auth.currentUser;
  const ref = doc(db, "users", user.uid);

  try {
    return updateDoc(ref, {
      firstStreamerUid: streamerUid || null,
    });
  } catch (error) {
    throw error;
  }

  // Firebase 8 stuff
  // const user = firebase.auth().currentUser;
  // if (!user) return;
  // return db
  //   .collection("users")
  //   .doc(user.uid)
  //   .update({
  //     firstStreamerUid: streamerUid || null,
  //   });
}

export async function getFeedback() {
  const ref = collection(db, "aftermatchfeedback");
  const querySnapshot = await getDocs(ref);
  const data = [];
  querySnapshot.forEach((doc) => {
    // console.log(doc.id, " => ", doc.data());
    data.push(doc.data());
  });
  return data;
}

export async function getGlobalStats() {
  const ref = collection(db, "stats");
  const querySnapshot = await getDocs(ref);
  const data = [];
  querySnapshot.forEach((doc) => {
    // console.log(doc.id, " => ", doc.data());
    data.push(doc.data());
  });
  return data;
}

export async function getStreamers() {
  const ref = collection(db, "streamerurls");
  const querySnapshot = await getDocs(ref);
  const data = [];
  querySnapshot.forEach((doc) => {
    // console.log(doc.id, " => ", doc.data());
    data.push(doc.data());
  });
  return data;
}

export async function streamerGetTopUsers(streamerUid) {
  const ref = collection(db, "streamers_users", streamerUid, "users");
  const q = query(ref, orderBy("numJoins", "desc"), limit(10));

  const querySnapshot = await getDocs(q);
  const data = [];
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    // console.log(doc.id, " => ", doc.data());
    data.push(doc.data());
  });
  return data;
}

export async function streamerGetNumUsersNeverPlayed(streamerUid) {
  const coll = collection(db, "streamers_users", streamerUid, "users");
  const q = query(
    coll,
    where("numSelected", "==", 0),
    where("numJoins", ">", 0)
  );
  const snapshot = await getCountFromServer(q);
  return snapshot.data().count;
}

export async function streamerGetNumUsersPlayed(streamerUid) {
  const coll = collection(db, "streamers_users", streamerUid, "users");
  const q = query(coll, where("numSelected", ">", 0));
  const snapshot = await getCountFromServer(q);
  return snapshot.data().count;
}

export async function streamerGetNumLobbies(streamerUid) {
  const coll = collection(db, "lobbylogs", streamerUid, "lobbies");
  const q = query(coll, where("status", "==", "finished"));
  const snapshot = await getCountFromServer(q);
  return snapshot.data().count;
}

export async function streamerGetNumUsers(streamerUid) {
  const coll = collection(db, "streamers_users", streamerUid, "users");
  const snapshot = await getCountFromServer(coll);
  return snapshot.data().count;
}

export async function getNumUsers() {
  const coll = collection(db, "users");
  const snapshot = await getCountFromServer(coll);
  return snapshot.data().count;
}

export function getUserProfile(userId) {
  return doc(db, "users", userId);
  // return db.collection("users").doc(userId);
}

export function getStreamerProfile(userId) {
  // return doc(db, "users", userId);
  return doc(db, "users", userId);
  // return db.collection("users").doc(userId);
}

export function getStreamerUidByURL(url) {
  if (!url || url === "") {
    return new Promise((resolve) => {
      resolve(false);
    });
  }
  const docRef = doc(db, "streamerurls", url);
  // var docRef = db.collection("streamerurls").doc(url);

  return getDoc(docRef)
    .then((doc) => {
      if (doc.exists()) {
        return doc.data().uid;
      } else {
        return false;
      }
    })
    .catch((error) => {
      console.log("Error getting document:", error);
    });
}

export async function updateQuizItem(itemId, values) {
  // Firebase 8 stuff
  // const user = firebase.auth().currentUser;
  const auth = getAuth();
  const user = auth.currentUser;
  const ref = doc(db, "quizitems", user.uid, "questions", itemId);

  try {
    return updateDoc(ref, {
      updatedAt: serverTimestamp(),
      ...values,
    });

    // Firebase 8 stuff
    // return await db
    //   .collection("quizitems")
    //   .doc(user.uid)
    //   .collection("questions")
    //   .doc(itemId)
    //   .update({
    //     updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    //     ...values,
    //   });
  } catch (error) {
    throw error;
  }
}

export async function addQuizItem(values) {
  // Firebase 8 stuff
  // const user = firebase.auth().currentUser;
  const auth = getAuth();
  const user = auth.currentUser;

  try {
    const ref = collection(db, "quizitems", user.uid, "questions");
    return addDoc(ref, {
      createdAt: serverTimestamp(),
      ...values,
    });

    //  Firebase 8 stuff
    // return await db
    //   .collection("quizitems")
    //   .doc(user.uid)
    //   .collection("questions")
    //   .doc()
    //   .set({
    //     createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    //     ...values,
    //   });
  } catch (error) {
    throw error;
  }
}

export async function saveAfterMatchFeedback(values) {
  const auth = getAuth();
  const user = auth.currentUser;

  const docId = `${values.lobbyId}-${user.uid}`;

  try {
    const ref = doc(db, "aftermatchfeedback", docId);
    return await setDoc(ref, {
      createdAt: serverTimestamp(),
      ...values,
    });

    // Firebase 8 stuff
    // return await db
    //   .collection("aftermatchfeedback")
    //   .doc(values.lobbyId)
    //   .collection("users")
    //   .doc(user.uid)
    //   .set({
    //     createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    //     ...values,
    //   });
  } catch (error) {
    throw error;
  }
}

export async function deleteUserProfile(profile) {
  const user = firebase.auth().currentUser;

  try {
    //streamerURL
    var delete_query = db
      .collection("streamerurls")
      .where("uid", "==", user.uid);
    delete_query.get().then(function (querySnapshot) {
      querySnapshot.forEach(function (doc) {
        doc.ref.delete();
      });
    });

    await db
      .collection("users_deleted")
      .doc(user.uid)
      .set({
        deletedAt: firebase.firestore.FieldValue.serverTimestamp(),
        ...profile,
      });

    await user.delete();

    // Users
    return await db.collection("users").doc(user.uid).delete();
  } catch (error) {
    throw error;
  }
}

export async function updateUserProfile(profile) {
  // const user = firebase.auth().currentUser;
  const auth = getAuth();
  const user = auth.currentUser;

  try {
    // Updating the Auth Account Data (name/Photo)
    if (user.displayName !== profile.displayName) {
      updateProfile(auth.currentUser, {
        displayName: profile.displayName,
      }).catch((error) => {});
      // await user.updateProfile({
      //   displayName: profile.displayName,
      // });
    }
    if (profile.photoURL) {
      updateProfile(auth.currentUser, {
        displayName: profile.displayName,
      }).catch((error) => {});
      // await user.updateProfile({
      //   photoURL: profile.photoURL,
      // });
    }

    if (profile.url) {
      await updateStreamerURL(user.uid, profile.url);
    }

    let updatedProfile = profile;

    if (profile.legalAcced) {
      updatedProfile.legalVersionAcced =
        process.env.REACT_APP_CURRENT_LEGAL_VER;
      delete updatedProfile.legalAcced;
    }

    if (profile.joinedLobby) {
      const myArrayUnion = arrayUnion(profile.joinedStreamerId);
      updatedProfile = {
        ...updatedProfile,
        joinedStreamers: myArrayUnion,
        // joinedStreamers: firebase.firestore.FieldValue.arrayUnion(
        //   profile.joinedStreamerId
        // ),
      };
    }

    const userRef = doc(db, "users", user.uid);
    return await updateDoc(userRef, {
      ...updatedProfile,
    });
    // return await db.collection("users").doc(user.uid).update(updatedProfile);
  } catch (error) {
    throw error;
  }
}

export async function updateStreamerURL(id, url) {
  try {
    const ref = collection(db, "streamerurls");
    const q = query(ref, where("uid", "==", id), where("url", "!=", url));

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((queriedDoc) => {
      deleteDoc(queriedDoc.ref);
    });

    // Firebase 8 stuff
    // var delete_query = db
    //   .collection("streamerurls")
    //   .where("uid", "==", id)
    //   .where("url", "!=", url);
    // delete_query.get().then(function (querySnapshot) {
    //   querySnapshot.forEach(function (doc) {
    // doc.ref.delete();
    //     console.log(doc);
    //
    //   });
    // });
  } catch (error) {
    throw error;
  }

  const ref = doc(db, "streamerurls", url);
  return await setDoc(ref, {
    uid: id,
    url: url,
  });

  // Firebase 8 stuff
  // return await db.collection("streamerurls").doc(url).set({
  //   uid: id,
  //   url: url,
  // });
}

export async function updateUserProfilePhoto(downloadURL, filename) {
  //Firebase 8 stuff
  // const user = firebase.auth().currentUser;

  const auth = getAuth();
  const user = auth.currentUser;
  let downloadURLsmall;
  downloadURLsmall = downloadURL.replace(".jpg", "_128x128.webp");
  downloadURLsmall = downloadURL.replace(".png", "_128x128.webp");

  try {
    const docRef = doc(db, "users", user.uid);
    const userDoc = await getDoc(docRef);

    if (userDoc.data().photoFilename) {
      deleteFromFirebaseStorage(userDoc.data().photoFilename);
    }

    await updateDoc(doc(db, "users", user.uid), {
      photoURL: downloadURL,
      photoURLsmall: downloadURLsmall,
      photoFilename: filename,
    });

    return await updateProfile(user, {
      photoURL: downloadURL,
    });

    // Firebase 8 stuff
    // const userDocRef = db.collection("users").doc(user.uid);
    // const downloadURLsmall = downloadURL.replace(".jpg", "_128x128.webp");
    // try {
    //   const userDoc = await userDocRef.get();
    //   if (userDoc.data().photoFilename) {
    //     deleteFromFirebaseStorage(userDoc.data().photoFilename);
    //   }
    //   await db.collection("users").doc(user.uid).update({
    //     photoURL: downloadURL,
    //     photoURLsmall: downloadURLsmall,
    //     photoFilename: filename,
    //   });

    //   return await user.updateProfile({
    //     photoURL: downloadURL,
    //   });
  } catch (error) {
    throw error;
  }
}

export function getUserPhotos(userUid) {
  return db.collection("users").doc(userUid).collection("photos");
}

export function getCurrentUser() {
  return firebase.auth().currentUser;
}

export function deletePhotoFromCollection(photoId) {
  const userUid = firebase.auth().currentUser.uid;
  return db
    .collection("users")
    .doc(userUid)
    .collection("photos")
    .doc(photoId)
    .delete();
}

export async function createLobbylogInFirestore(lobbyId, values) {
  // Firebase 8 stuff
  // const user = firebase.auth().currentUser;
  const auth = getAuth();
  const user = auth.currentUser;

  // const ref = doc(db, "lobbylogs", user.uid, "questions", itemId);
  await setDoc(doc(db, "lobbylogs", user.uid), {
    streamerDisplayName: user.displayName,
    streamerUid: user.uid,
  });

  await setDoc(doc(db, "lobbylogs", user.uid, "lobbies", lobbyId), {
    streamerDisplayName: user.displayName,
    userCount: 0,
    createdAt: serverTimestamp(),
    ...values,
  });

  // Firebase 8 stuff
  // await db
  //   .collection("lobbylogs")
  //   .doc(user.uid)
  //   .collection("lobbies")
  //   .doc(lobbyId)
  //   .set({
  //     streamerDisplayName: user.displayName,
  //     userCount: 0,
  //     createdAt: firebase.firestore.FieldValue.serverTimestamp(),
  //     ...values,
  //   });

  // return db
  //   .collection("lobbylogs")
  //   .doc(user.uid)
  //   .collection("lobbies")
  //   .doc(lobbyId)
  //   .collection("log")
  //   .add({
  //     action: "lobby_created",
  //     userId: user.uid,
  //     displayName: user.displayName,
  //     createdAt: firebase.firestore.FieldValue.serverTimestamp(),
  //   });
}

export function deleteDiscordChannelViaFirestore(lobbyId) {
  const values = {
    updatedAt: serverTimestamp(),
    status: "tobedeleted",
  };
  // const currentUser = firebase.auth().currentUser;
  const auth = getAuth();
  const currentUser = auth.currentUser;

  const ref = doc(db, "discordchannels", currentUser.uid, "channels", lobbyId);
  return setDoc(
    ref,
    {
      ...values,
    },
    { merge: true }
  );

  // Firebase 8 stuff
  // return db
  //   .collection("discordchannels")
  //   .doc(currentUser.uid)
  //   .collection("channels")
  //   .doc(lobbyId)
  //   .set(
  //     {
  //       ...values,
  //     },
  //     { merge: true }
  //   );
}

export async function createDiscordChannelsViaFirestore(
  lobbyId,
  selectedLobby
) {
  let users = [];
  let user = [];

  // const selectedLobby = await getLobbyFromFirebase(lobbyId);
  const auth = getAuth();
  const currentUser = auth.currentUser;

  // return;

  selectedLobby.selectedUsers?.forEach((teams, tindex) => {
    teams.forEach((player) => {
      let discordaccount = player.accounts?.filter((e) => e.type === "discord");

      if (discordaccount) {
        user = {
          team: tindex,
          userId: player.userId,
          displayName: player.displayName,
          discordUserId: discordaccount[0]?.discordId || null,
          discordaccount: discordaccount[0]?.value || null,
        };
        users.push(user);
      }
    });
  });

  const values = {
    updatedAt: serverTimestamp(),
    status: "pending",
    numTeams: selectedLobby.selectedUsers.length,
    lobbyName: `Streamup with ${selectedLobby.selectedUsers[0][0].displayName}`,
    textChannelName: `general`,
    voiceChannelName: selectedLobby.game ? selectedLobby.game : "Game Session",
    lobbyId: lobbyId,
    users: users,
  };

  const ref = doc(db, "discordchannels", currentUser.uid, "channels", lobbyId);

  return setDoc(ref, {
    ...values,
  });

  // Firebase 8 stuff
  // return db
  //   .collection("discordchannels")
  //   .doc(currentUser.uid)
  //   .collection("channels")
  //   .doc(lobbyId)
  //   .set({
  //     ...values,
  //   });
}

export function updateLobbylogInFirestore(lobbyId, streamerId, values) {
  const ref = doc(db, "lobbylogs", streamerId, "lobbies", lobbyId);
  return updateDoc(ref, {
    ...values,
  });

  // Firebase 8 stuff
  // return db
  //   .collection("lobbylogs")
  //   .doc(streamerId)
  //   .collection("lobbies")
  //   .doc(lobbyId)
  //   .update({
  //     ...values,
  //   });
}

export function addEntryLobbylogInFirestore(
  lobbyId,
  streamerId,
  action,
  twitchChannel = ""
) {
  const auth = getAuth();
  const user = auth.currentUser;
  const ref = collection(
    db,
    "lobbylogs",
    streamerId,
    "lobbies",
    lobbyId,
    "log"
  );
  return addDoc(ref, {
    action: action,
    userId: user.uid,
    displayName: user.displayName,
    createdAt: serverTimestamp(),
    twitchChannelSubscription: twitchChannel,
  });

  // Firebase 8 stuff
  // const user = firebase.auth().currentUser;
  // return db
  //   .collection("lobbylogs")
  //   .doc(streamerId)
  //   .collection("lobbies")
  //   .doc(lobbyId)
  //   .collection("log")
  //   .add({
  //     action: action,
  //     userId: user.uid,
  //     displayName: user.displayName,
  //     createdAt: firebase.firestore.FieldValue.serverTimestamp(),
  //     twitchChannelSubscription: twitchChannel,
  //   });
}

export function getUsersTwitchChannelsSubscriptions(twitchChannel, userId) {
  return doc(
    db,
    "users_twitchchannels_subscription",
    twitchChannel,
    "users",
    userId
  );

  // return db
  //   .collection("users_twitchchannels_subscription")
  //   .doc(twitchChannel)
  //   .collection("users")
  //   .doc(userId);
}

export function getStreamersUsersStatsFirestore(streamerId, userId) {
  return doc(db, "streamers_users", streamerId, "users", userId);

  // return db
  //   .collection("streamers_users")
  //   .doc(streamerId)
  //   .collection("users")
  //   .doc(userId);
}

export async function listenToStreamersUsersStatsFirestore(streamerId, userId) {
  return db
    .collection("streamers_users")
    .doc(streamerId)
    .collection("users")
    .doc(userId);
}

export async function updateStreamersUsersStatsFirestore(
  streamerId,
  userId,
  type
) {
  let newValues = {};
  let incrementSelected = 0;
  let incrementNotSelected = 0;

  if (type === "selected") {
    incrementSelected = 1;
    incrementNotSelected = 0;
  } else {
    incrementSelected = 0;
    incrementNotSelected = 1;
  }

  const docRef = doc(db, "streamers_users", streamerId, "users", userId);

  getDoc(docRef)
    .then((doc) => {
      newValues = {
        numSelected:
          doc.data().numSelected === undefined
            ? incrementSelected
            : doc.data().numSelected + incrementSelected,
        numNotSelected:
          doc.data().numNotSelected === undefined
            ? incrementNotSelected
            : doc.data().numNotSelected + incrementNotSelected,
      };

      // if selected, reset the notSelectedStreak to 0
      if (type === "selected") {
        newValues.numNotSelectedStreak = 0;
      } else {
        newValues.numNotSelectedStreak =
          doc.data().numNotSelectedStreak === undefined
            ? incrementNotSelected
            : doc.data().numNotSelectedStreak + incrementNotSelected;
      }

      return updateDoc(docRef, {
        newValues,
      });
      // Firebase 8 stuff
      // return db
      //   .collection("streamers_users")
      //   .doc(streamerId)
      //   .collection("users")
      //   .doc(userId)
      //   .update(newValues);
    })
    .catch((error) => {
      console.log("Error getting document:", error);
    });

  // Firebase 8 stuff
  // const doc = await db
  //   .collection("streamers_users")
  //   .doc(streamerId)
  //   .collection("users")
  //   .doc(userId)
  //   .get();
}

export function addUserAttendance(event) {
  const user = firebase.auth().currentUser;
  return db
    .collection("events")
    .doc(event.id)
    .update({
      attendees: firebase.firestore.FieldValue.arrayUnion({
        id: user.uid,
        displayName: user.displayName,
        photoURL: user.photoURL || null,
      }),
      attendeesIds: firebase.firestore.FieldValue.arrayUnion(user.uid),
    });
}

export async function cancelUserAttendance(event) {
  const user = firebase.auth().currentUser;
  try {
    const eventDoc = await db.collection("events").doc(event.id).get();
    return db
      .collection("events")
      .doc(event.id)
      .update({
        attendeesIds: firebase.firestore.FieldValue.arrayRemove(user.uid),
        attendees: eventDoc
          .data()
          .attendees.filter((attendee) => attendee.id !== user.uid),
      });
  } catch (error) {
    throw error;
  }
}

export function getUserEventsQuery(activeTab, userUid) {
  let eventsRef = db.collection("events");
  const today = new Date();
  switch (activeTab) {
    case 1: // Past Events
      return eventsRef
        .where("attendeesIds", "array-contains", userUid)
        .where("date", "<=", today)
        .orderBy("date", "desc");
    case 2: // Hosted Events
      return eventsRef.where("hostUid", "==", userUid).orderBy("date");

    default:
      return eventsRef
        .where("attendeesIds", "array-contains", userUid)
        .where("date", ">=", today)
        .orderBy("date", "desc");
  }
}

export async function removeUserAccountToFirestore(
  currentUserProfile,
  accountType
) {
  const auth = getAuth();
  const userUid = auth.currentUser.uid;

  let accountOld;
  if (currentUserProfile.accounts)
    currentUserProfile.accounts.forEach((a) => {
      if (a.type === accountType) accountOld = a;
    });
  const batch = writeBatch(db);

  try {
    if (accountOld) {
      const myArrayRemoveAccount = arrayRemove(accountOld);
      batch.update(doc(db, "users", userUid), {
        accounts: myArrayRemoveAccount,
      });
    }
    const myArrayRemoveType = arrayRemove(accountType);
    batch.update(doc(db, "users", userUid), {
      accountsKeys: myArrayRemoveType,
    });
    return await batch.commit();
  } catch (error) {
    throw error;
  }
}

export async function addUserAccountToFirestore(currentUserProfile, account) {
  const auth = getAuth();
  const userUid = auth.currentUser.uid;
  let accountOld;

  if (currentUserProfile.accounts)
    currentUserProfile.accounts.forEach((a) => {
      if (a.type === account.type) accountOld = a;
    });

  const batch = writeBatch(db);

  try {
    if (accountOld) {
      const myArrayRemoveAccount = arrayRemove(accountOld);
      batch.update(doc(db, "users", userUid), {
        accounts: myArrayRemoveAccount,
      });
      const myArrayRemoveType = arrayRemove(account.type);
      batch.update(doc(db, "users", userUid), {
        accountsKeys: myArrayRemoveType,
      });
    }
    const myArrayUnionAccount = arrayUnion(account);
    const myArrayUnionType = arrayUnion(account.type);
    batch.update(doc(db, "users", userUid), {
      accounts: myArrayUnionAccount,
      accountsKeys: myArrayUnionType,
    });

    return await batch.commit();
  } catch (error) {
    throw error;
  }
}
