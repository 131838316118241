import React from "react";
import { useField } from "formik";
import { Select, FormLabel, Badge, Box } from "@chakra-ui/react";

export default function MySelectInput({ label, options, ...props }) {
  const [field, meta, helpers] = useField(props);
  return (
    <Box position='relative'>
      <Select
        mt='7'
        mb='3'
        isInvalid={meta.touched && !!meta.error}
        variant='flushed'
        size='lg'
        height='30px'
        pt='15px'
        value={field.value || null}
        onChange={(e) => helpers.setValue(e.target.value)}
        onBlur={() => helpers.setTouched(true)}
        {...props}
      >
        {options.map((option) => (
          <option value={option.value} key={option.value}>
            {option.text}
          </option>
        ))}
      </Select>
      <FormLabel top='-19px' htmlFor='name'>
        {label}:
      </FormLabel>

      <Badge
        marginTop='0'
        position='absolute'
        bottom='-20px'
        left='0'
        colorScheme='red'
      >
        {meta.touched && meta.error ? meta.error : null}
      </Badge>
    </Box>
  );
}
