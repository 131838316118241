import React from "react";
import { lazy, Suspense } from "react";
import { useSelector } from "react-redux";
import TestModal from "../../../features/sandbox/TestModal";
import LoginForm from "../../../features/auth/LoginForm";
import EmailPasswordForm from "../../../features/auth/EmailPasswordForm";
import RegisterForm from "../../../features/auth/RegisterForm";
import PhotoUploadWidget from "../photos/PhotoUploadWidget";
import AccountForm from "../../../features/profiles/settings/AccountForm";
import DisplayNameForm from "../../../features/auth/DisplayNameForm";
import StreamerSettingsQuizEditForm from "../../../features/profiles/streamerSettings/StreamerSettingsQuizEditForm";
import StreamerSettingsQuizImportForm from "../../../features/profiles/streamerSettings/StreamerSettingsQuizImportForm";

const SelectorSelection = lazy(() =>
  import("../../../features/selectors/selectorSelection/SelectorSelection")
);
const QuickFinalRandom = lazy(() =>
  import("../../../features/selectors/quickfinalrandom/QuickFinalRandom")
);
const SortAndCut = lazy(() =>
  import("../../../features/selectors/sortandcut/SortAndCut")
);
const Quiz = lazy(() => import("../../../features/selectors/quiz/Quiz"));
const Slotmachine = lazy(() =>
  import("../../../features/selectors/slotmachine/Slotmachine")
);
const QuizUserAnswerForm = lazy(() =>
  import("../../../features/selectors/quiz/QuizUserAnswerForm")
);
const Thanos = lazy(() => import("../../../features/selectors/thanos/Thanos"));

export default function ModalManager() {
  const modalLookup = {
    TestModal,
    LoginForm,
    RegisterForm,
    PhotoUploadWidget,
    SelectorSelection,
    QuickFinalRandom,
    Slotmachine,
    Thanos,
    SortAndCut,
    Quiz,
    QuizUserAnswerForm,
    EmailPasswordForm,
    AccountForm,
    DisplayNameForm,
    StreamerSettingsQuizEditForm,
    StreamerSettingsQuizImportForm,
  };

  const currrentModal = useSelector((state) => state.modals);

  let renderedModal;
  if (currrentModal) {
    const { modalType, modalProps } = currrentModal;

    const ModalComponent = modalLookup[modalType];
    renderedModal = <ModalComponent {...modalProps} />;
  }

  return (
    <span>
      <Suspense fallback=<div>Loading...</div>>{renderedModal}</Suspense>
    </span>
  );
}
