import { Button } from "@chakra-ui/react";
import { Box, Flex, VStack, Spacer, Text, Link } from "@chakra-ui/layout";
import React, { useState } from "react";
import { GiExitDoor } from "react-icons/gi";
import { MdAddBox } from "react-icons/md";
import { useToast } from "@chakra-ui/toast";
import { useDispatch, useSelector } from "react-redux";
import { accountTypes } from "../../../app/api/accounttypes";
import { closeModal, openModal } from "../../../app/common/modals/modalReducer";
import UnauthModal from "../../auth/UnauthModal";
import {
  getUserAuthToken,
  joinLobbyInFirebase,
  leaveLobbyInFirebase,
} from "../../../app/firestore/firebaseService";
import {
  addEntryLobbylogInFirestore,
  getStreamersUsersStatsFirestore,
  getUsersTwitchChannelsSubscriptions,
  updateUserProfile,
} from "../../../app/firestore/firestoreService";
import useFirestoreDoc from "../../../app/hooks/useFirestoreDoc";

export default function LobbyUserActions({
  selectedLobby,
  currentUserProfile,
  streamerProfile,
}) {
  const dispatch = useDispatch();
  const lobbyId = selectedLobby.lobbyId;
  const [modalOpen, setModalOpen] = useState(false);
  // const [token, setToken] = useState(false);
  const { authenticated } = useSelector((state) => state.auth);
  const [userStreamerStats, setUserStreamerStats] = useState(false);
  const [userStreamerStatsLoaded, setUserStreamerStatsLoaded] = useState(false);
  const [twitchChannelUserSubscription, setTwitchChannelUserSubscription] =
    useState({ status: "loading" });
  const [subscriptionLoading, setSubscriptionLoading] = useState(true);
  const toast = useToast();

  const validChars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let array = new Uint8Array(40);
  window.crypto.getRandomValues(array);
  array = array.map((x) => validChars.charCodeAt(x % validChars.length));

  // useEffect(() => {
  //   async function handleUsertoken() {
  //     if (currentUserProfile?.id) {
  //       const token = await getUserAuthToken();
  //       setToken(token);
  //     }
  //   }
  //   if (selectedLobby.twitchSubscribersOnly) {
  //     handleUsertoken();
  //   }
  // }, [currentUserProfile?.id, selectedLobby.twitchSubscribersOnly]);

  useFirestoreDoc({
    query: () =>
      getUsersTwitchChannelsSubscriptions(
        selectedLobby.twitchChannel,
        currentUserProfile?.id
      ),
    shouldExecute:
      currentUserProfile?.id !== undefined &&
      selectedLobby.twitchChannel !== "",
    data: (data) => setTwitchChannelUserSubscription(data),
    deps: [dispatch, selectedLobby.twitchChannel, currentUserProfile?.id],
    afterFinished: () => setSubscriptionLoading(false),
  });

  useFirestoreDoc({
    query: () =>
      getStreamersUsersStatsFirestore(
        streamerProfile.id,
        currentUserProfile?.id
      ),
    shouldExecute: currentUserProfile?.id !== undefined,
    data: (stats) => setUserStreamerStats(stats),
    deps: [dispatch, streamerProfile.id, currentUserProfile?.id],
    afterFinished: () => setUserStreamerStatsLoaded(true),
  });

  const currentUserLobbyUserId = selectedLobby?.users?.filter(
    (u) => u.userId === currentUserProfile?.id || null
  )[0]?.id;

  const numAccountsNeededToAdd = selectedLobby.accountsNeeded?.filter(
    (type) => !currentUserProfile?.accountsKeys?.includes(type)
  ).length;

  const subscriberVerified =
    (selectedLobby.twitchSubscribersOnly && !subscriptionLoading) ||
    !selectedLobby.twitchSubscribersOnly;

  let twitchChannelUserSubscriptionStatus;
  if (twitchChannelUserSubscription?.updated) {
    const dateUpdated = new Date(twitchChannelUserSubscription.updated);
    const dateGate = new Date();
    dateGate.setDate(dateGate.getDate() - 7); // how many days we dont need to re-check

    if (
      twitchChannelUserSubscription.status === "subscribed" &&
      dateGate.getTime() - dateUpdated.getTime() > 0
    ) {
      twitchChannelUserSubscriptionStatus = undefined;
    } else {
      twitchChannelUserSubscriptionStatus =
        twitchChannelUserSubscription.status;
    }
  }

  function handleCloseAccountForm() {
    dispatch(closeModal());
  }

  async function handleJoin() {
    if (currentUserLobbyUserId) return;

    try {
      let twitchChannel = "";
      if (selectedLobby.twitchSubscribersOnly) {
        twitchChannel = selectedLobby.twitchChannel;
      }

      addEntryLobbylogInFirestore(
        lobbyId,
        streamerProfile.id,
        "user_join",
        twitchChannel
      )
        .then((r) => {
          joinLobbyInFirebase(
            lobbyId,
            currentUserProfile,
            userStreamerStats
              ? userStreamerStats
              : { firstJoin: new Date(Date.now()).toDateString() }
          ).then((response) => {
            updateUserProfile({
              joinedLobby: lobbyId,
              joinedStreamerURL: streamerProfile.url,
              joinedStreamerId: streamerProfile.id,
            });
          });
          toast({
            title: "Lobby joined",
            description:
              "You have joined this lobby. Now please wait until the next phase begins.",
            status: "success",
            isClosable: true,
          });
        })
        .catch((error) => {
          toast({
            title: "Error",
            description: error.message,
            status: "error",
            isClosable: true,
          });
        });
    } catch (error) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        isClosable: true,
      });
    }
  }

  async function handleLeave() {
    if (!currentUserLobbyUserId) return;

    try {
      await leaveLobbyInFirebase(lobbyId, currentUserLobbyUserId).then(
        (response) => {
          updateUserProfile({
            joinedLobby: null,
            joinedStreamerURL: null,
            joinedStreamerId: null,
          });
        }
      );
      await addEntryLobbylogInFirestore(
        lobbyId,
        streamerProfile.id,
        "user_leave"
      );
    } catch (error) {
      console.log(error.message);
      // toast.error(error.message);
    }
  }

  function gotoSubscriptionValidation() {
    getUserAuthToken().then((token) => {
      const verifySubriptionUrl =
        `${process.env.REACT_APP_FUNCTION_URL}/api/twitch/subscriptioncheck/auth` +
        `?twitchChannel=${selectedLobby.twitchChannel}` +
        `&returnto=${window.location.href}` +
        `&token=${token}`;
      // console.log("sending to ", verifySubriptionUrl);
      window.location.replace(verifySubriptionUrl);
    });
  }

  return (
    <>
      {modalOpen && <UnauthModal setModalOpen={setModalOpen} />}
      <Box
        m='2'
        h={currentUserLobbyUserId ? "auto" : "auto"}
        // className='smoothresize'
        style={{ overflow: "hidden" }}
      >
        {currentUserProfile &&
          !currentUserLobbyUserId &&
          selectedLobby.twitchSubscribersOnly &&
          subscriberVerified &&
          twitchChannelUserSubscriptionStatus !== "subscribed" && (
            <VStack justifyContent='center' mt='30px'>
              <Text>
                To join this lobby, you need to be a Twitch subscriber to{" "}
                <span>
                  <Link
                    isexternal='true'
                    target='_blank'
                    href={`https://www.twitch.tv/${selectedLobby.twitchChannel}`}
                  >
                    twitch.tv/{selectedLobby.twitchChannel}
                  </Link>
                </span>
                .
              </Text>
              {twitchChannelUserSubscriptionStatus !== "unsubscribed" && (
                <Box>
                  <Button
                    mx={"1px"}
                    as={Link}
                    onClick={() => gotoSubscriptionValidation()}
                    variant='primary'
                  >
                    Verify Subscription Status
                  </Button>
                </Box>
              )}
              {twitchChannelUserSubscriptionStatus === "unsubscribed" && (
                <Box color={"warning"}>
                  It seems you're not subscribed :-( &#160;
                  <Link
                    fontSize={"sm"}
                    onClick={() => gotoSubscriptionValidation()}
                  >
                    Check again
                  </Link>
                </Box>
              )}
            </VStack>
          )}
        {selectedLobby.twitchSubscribersOnly &&
          twitchChannelUserSubscriptionStatus === "subscribed" &&
          !currentUserLobbyUserId && (
            <VStack justifyContent='center' mt='30px'>
              <Box>
                You're subscribed to{" "}
                <Box display={"inline"}>
                  <Link
                    isexternal='true'
                    target='_blank'
                    href={`https://www.twitch.tv/${selectedLobby.twitchChannel}`}
                  >
                    twitch.tv/{selectedLobby.twitchChannel}
                  </Link>
                </Box>
                , thank you!&#160;
              </Box>
            </VStack>
          )}
        {currentUserProfile &&
          selectedLobby.accountsNeeded?.length > 0 &&
          numAccountsNeededToAdd > 0 && (
            <VStack justifyContent='center' m='30px'>
              <Text>
                To join this lobby, you need to add the following account
                {numAccountsNeededToAdd > 1 ? "s" : ""}:
              </Text>
              <Box>
                {selectedLobby.accountsNeeded
                  ?.filter(
                    (type) => !currentUserProfile?.accountsKeys?.includes(type)
                  )
                  .map((type) => (
                    <Button
                      key={type}
                      leftIcon={<MdAddBox />}
                      mx={"1px"}
                      onClick={() => {
                        dispatch(
                          openModal({
                            modalType: "AccountForm",
                            modalProps: {
                              accountType: type,
                              accountTypes: accountTypes,
                              currentUserProfile: currentUserProfile,
                              handleClose: handleCloseAccountForm,
                            },
                          })
                        );
                      }}
                      aize='sm'
                      // textColor='primary'
                      variant='primary'
                    >
                      {accountTypes
                        .filter((a) => a.key === type)
                        .map((a) => a.name)}
                    </Button>
                  ))}
              </Box>
            </VStack>
          )}
        <Flex>
          {currentUserProfile &&
            !currentUserLobbyUserId &&
            userStreamerStatsLoaded &&
            selectedLobby.status === "open" && (
              <>
                <Flex
                  flex='1'
                  flexDirection='column'
                  justifyContent='center'
                ></Flex>
                <Box flex='1' textAlign='center'>
                  <Button
                    mt='20px'
                    mb='20px'
                    minWidth={"300px"}
                    variant='primary'
                    id='join_lobby'
                    isDisabled={
                      !currentUserProfile ||
                      numAccountsNeededToAdd > 0 ||
                      !subscriberVerified ||
                      (selectedLobby.twitchSubscribersOnly &&
                        twitchChannelUserSubscriptionStatus !== "subscribed")
                    }
                    size='lg'
                    onClick={
                      authenticated ? handleJoin : () => setModalOpen(true)
                    }
                  >
                    {subscriberVerified || !currentUserProfile
                      ? "Join Lobby"
                      : "Checking Twitch Subscription"}
                  </Button>
                </Box>
              </>
            )}
          {!currentUserProfile && selectedLobby.status === "open" && (
            <>
              <Flex
                flex='1'
                flexDirection='column'
                justifyContent='center'
              ></Flex>
              <Box flex='1' textAlign='center'>
                <Button
                  mt='20px'
                  mb='20px'
                  variant='primary'
                  id='join_lobby'
                  size='lg'
                  onClick={() => setModalOpen(true)}
                >
                  Login to Join
                </Button>
              </Box>
            </>
          )}
          <Spacer />
          {currentUserLobbyUserId &&
            selectedLobby.status !== "all_selected" && (
              <Button size='sm' id='leave_lobby' onClick={handleLeave}>
                <GiExitDoor />
                &#160; Leave Lobby
              </Button>
            )}
        </Flex>
        {!currentUserLobbyUserId && selectedLobby.description && (
          <Box
            borderTopRadius='20px'
            bg={"gray.900"}
            p={"10px 20px"}
            borderRadius={"20px"}
            maxWidth={"600px"}
            m={"auto"}
          >
            <Text>{selectedLobby.description}</Text>
          </Box>
        )}
      </Box>
    </>
  );
}
