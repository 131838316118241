import React, { useState } from "react";
import { useSelector } from "react-redux";
import { FormControl } from "@chakra-ui/form-control";
import { Form, Formik } from "formik";
import MyTextArea from "../../../app/common/form/MyTextArea";

import * as Yup from "yup";
import { Link as ReactLink, useNavigate } from "react-router-dom";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Heading,
  Box,
  Link,
  Text,
  VStack,
  HStack,
  Stack,
  Image,
} from "@chakra-ui/react";
import { RiEmotionHappyLine, RiEmotionUnhappyLine } from "react-icons/ri";
import bgimg from "../../../assets/gfx/logo_v1_icon_bw.svg";

import { Button, ButtonGroup } from "@chakra-ui/react";
import { saveAfterMatchFeedback } from "../../../app/firestore/firestoreService";
import { useToast } from "@chakra-ui/toast";
import PaypalDonateButton from "../../../app/components/Paypal/PaypalDonateButton";

export default function AfterMatchModal({
  currentUserProfile,
  setModalOpen,
  selectedLobby,
  streamerProfile,
}) {
  const [setOpen] = useState(true);
  const [showContent, setShowContent] = useState("none");
  const navigate = useNavigate();
  const { prevLocation } = useSelector((state) => state.auth);
  const laststreamerUrl = localStorage.getItem("laststreamerUrl");
  const toast = useToast();

  function handleClose() {
    if (!navigate) {
      setModalOpen(false);
      return;
    }
    if (navigate && prevLocation) {
      navigate(prevLocation);
    } else {
      navigate(laststreamerUrl ? laststreamerUrl : "/");
    }
    if (typeof setOpen === "function") setOpen(false);
  }

  function saveWasFunOrNot(feedback) {
    const values = {
      lobbyId: selectedLobby.lobbyId,
      streamerDisplayName: streamerProfile.displayName,
      streamerUid: streamerProfile.id,
      userId: currentUserProfile.id,
      displayName: currentUserProfile.displayName,
      feedback: feedback,
      reason: "",
    };
    saveAfterMatchFeedback(values);
  }

  const noFun = (
    <Stack display={showContent === "nofun" ? "block" : "none"}>
      <Formik
        initialValues={{
          reason: "",
        }}
        validationSchema={Yup.object({
          reason: Yup.string().max(100).required(),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            const valuesToSave = {
              lobbyId: selectedLobby.lobbyId,
              streamerDisplayName: streamerProfile.displayName,
              streamerUid: streamerProfile.id,
              userId: currentUserProfile.id,
              displayName: currentUserProfile.displayName,
              feedback: "nofun",
              reason: values.reason,
            };
            saveAfterMatchFeedback(valuesToSave);
            toast({
              title: "Thank you",
              description: "for your feedback!",
              status: "success",
              isClosable: true,
            });
            handleClose();
          } catch (error) {
            // toast.error(error.message);
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, isValid, dirty }) => (
          <Form>
            <FormControl>
              <Stack w='full'>
                <Text>
                  That's not good. What's the reason for not being fun?
                </Text>
                <MyTextArea
                  rows='5'
                  name='reason'
                  // label='Reason'
                  placeholder='Tell us why!'
                />

                <Box w='full' mt='30px'>
                  <Button
                    isLoading={isSubmitting}
                    isDisabled={isSubmitting || !isValid}
                    type='submit'
                    w='full'
                    mt='30px'
                    variant='primary'
                  >
                    Save
                  </Button>
                </Box>
              </Stack>
            </FormControl>
          </Form>
        )}
      </Formik>
    </Stack>
  );

  const fun = (
    <VStack display={showContent === "fun" ? "block" : "none"}>
      <Heading size='md'>A good time is worth a few coins!</Heading>
      <Text>
        Isn't it? If you had a good time, please consider donating a small
        amount. For developing and maintaining <b>streamup.gg</b>.
      </Text>
      <HStack>
        <Box m='10px' width='150px' margin='30px auto'>
          <PaypalDonateButton />
        </Box>
      </HStack>
      <Box>
        Or read more about{" "}
        <Link as={ReactLink} to='/about/donation'>
          who's getting the money
        </Link>
        <br /> and what it's{" "}
        <Link as={ReactLink} to='/about/donation'>
          spend for
        </Link>
        .
      </Box>
    </VStack>
  );

  const userContent = (
    <>
      {showContent === "none" && (
        <Box>
          <Text mb='30px'>
            {streamerProfile.displayName} has closed this lobby.
          </Text>
          <Heading mb='30px' size='md' color='primary'>
            Was it fun playing with {streamerProfile.displayName}?
          </Heading>
          <ButtonGroup
            pb='50px'
            margin='auto'
            alignContent='center'
            textAlign='center'

            // width='200px'
          >
            <Button
              variant='solid'
              fontSize='xl'
              onClick={() => {
                setShowContent("fun");
                saveWasFunOrNot("fun");
              }}
              leftIcon={<RiEmotionHappyLine />}
            >
              Yes
            </Button>
            <Button
              variant='solid'
              fontSize='xl'
              onClick={() => {
                setShowContent("nofun");
                saveWasFunOrNot("nofun");
              }}
              leftIcon={<RiEmotionUnhappyLine />}
            >
              No
            </Button>
          </ButtonGroup>
        </Box>
      )}
      {fun}
      {noFun}
    </>
  );

  const streamerContent = (
    <>
      <Box>
        <Heading mb='30px' size='md'>
          What now, {streamerProfile.displayName}?
        </Heading>
        <ButtonGroup
          pb='50px'
          margin='auto'
          alignContent='center'
          textAlign='center'
        >
          <Button variant='primary' as={ReactLink} to='/createLobby'>
            Create another lobby
          </Button>
        </ButtonGroup>
        <Text>or</Text>
      </Box>
    </>
  );

  return (
    <Modal isCentered size='lg' isOpen={true} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent
        backgroundColor={"modalBg"}
        p={[0, 0]}
        borderRadius='20px'
        overflow={"hidden"}
      >
        <ModalHeader>Finished</ModalHeader>
        <ModalCloseButton />
        <ModalBody p={[1, 5]} minHeight='480px' textAlign='center'>
          <VStack verticalAlign='center' alignContent='center' mt='20px'>
            {currentUserProfile.id !== streamerProfile.id && <>{userContent}</>}
            {currentUserProfile.id === streamerProfile.id && (
              <>{streamerContent}</>
            )}
            <Box pt='50px'>
              <Button variant='outline' as={ReactLink} to={laststreamerUrl}>
                Cancel & Continue
              </Button>
            </Box>
          </VStack>
          <Image
            pointerEvents={"none"}
            position='absolute'
            left='10px'
            top='-100px'
            src={bgimg}
            height='120vh'
            width='100vw'
            opacity='0.02'
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
