// v9 compat packages are API compatible with v8 code
// import firebase from "firebase/compat/app";
// import "firebase/compat/firestore";
// import "firebase/compat/database";
// import "firebase/compat/auth";
// import "firebase/compat/storage";
// import "firebase/compat/functions";

import { initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBoOxtnvYm5UO4ENP5PEGpTH4MXr_iBmAI",
  // authDomain: "streamupgg.firebaseapp.com",
  authDomain: "www.streamup.gg",
  databaseURL:
    "https://streamupgg-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "streamupgg",
  storageBucket: "streamupgg.appspot.com",
  messagingSenderId: "273660482715",
  appId: "1:273660482715:web:4bed9961643e8da70d223e",
  measurementId: "G-516HB3JY42",
};

if (
  window.location.hostname === "localhost" &&
  process.env.REACT_APP_USE_EMULATOR === "true"
) {
  firebaseConfig.databaseURL =
    "http://localhost:9001/?ns=streamupgg-default-rtdb";
  firebaseConfig.storageBucket = "streamupgg.appspot.com";
  firebaseConfig.experimentalAutoDetectLongPolling = true;
}
// localhost:9199

// NOTE: do NOT put this in production.
// firebase.firestore().settings({  });

const app = initializeApp(firebaseConfig);
// const db = getFirestore();

if (
  window.location.hostname === "localhost" &&
  process.env.REACT_APP_USE_EMULATOR === "true"
) {
  const auth = getAuth();
  connectAuthEmulator(auth, "http://localhost:9099");
  // auth.useEmulator("http://localhost:9099");
}

export default app;
