import { Box } from "@chakra-ui/layout";
import React from "react";

export default function TextWallContainer({ children }) {
  return (
    <Box p='0 20px' mb={"33vh"}>
      {children}
    </Box>
  );
}
