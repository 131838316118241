import { useColorModeValue } from "@chakra-ui/color-mode";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { Box, HStack, Text, VStack } from "@chakra-ui/layout";
import {
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
} from "@chakra-ui/menu";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { Link as ChakraLink } from "@chakra-ui/react";
import MyAvatar from "../../app/components/MyAvatar/MyAvatar";
import { openModal } from "../../app/common/modals/modalReducer";

export default function SignedInMenu() {
  const { currentUserProfile } = useSelector((state) => state.profile);
  const location = useLocation();
  const bg = useColorModeValue("white", "gray.900");
  const borderColor = useColorModeValue("gray.200", "gray.700");
  const dispatch = useDispatch();

  if (!currentUserProfile) {
    return (
      <Box px={5}>
        Error loading Profile (
        <ChakraLink as={Link} to={"/logout"}>
          logout
        </ChakraLink>
        ).
      </Box>
    );
  }

  if (
    location.pathname !== "/terms-of-use" &&
    location.pathname !== "/privacy-policy"
  ) {
    if (
      !currentUserProfile.displayName?.trim() ||
      currentUserProfile.displayName === "Player1" ||
      process.env.REACT_APP_CURRENT_LEGAL_VER >
        currentUserProfile.legalVersionAcced ||
      !currentUserProfile.legalVersionAcced
    ) {
      dispatch(
        openModal({
          modalType: "DisplayNameForm",
          modalProps: {
            currentUserProfile: currentUserProfile,
            noClose: true,
          },
        })
      );
    }
  }

  return (
    <HStack
      spacing={{ base: "0", md: "6" }}
      _hover={{
        textDecoration: "none",
        bg: "gray.600",
      }}
      borderBottomLeftRadius='20px'
      borderBottomWidth='2px'
      border='transparent'
      pl='10px'
      pr='10px'
      height='calc(100% - 1px)'
    >
      {/* {currentUserProfile.joinedStreamerURL && (
        <Button
          size='md'
          as={Link}
          to={`/${currentUserProfile.joinedStreamerURL}`}
          variant='link'
          leftIcon={<BsFillArrowRightCircleFill />}
          ml='10'
        >
          Joined Lobby
        </Button>
      )}
      {currentUserProfile.createdLobby && (
        <Button
          size='md'
          as={Link}
          to={`/${currentUserProfile.url}`}
          variant='link'
          leftIcon={<BsFillArrowRightCircleFill />}
          ml='10'
        >
          Open Lobby
        </Button>
      )}
      {laststreamerUrl &&
        !currentUserProfile.createdLobby &&
        !currentUserProfile.joinedStreamerURL && (
          <Button
            size='md'
            as={Link}
            to={`${laststreamerUrl}`}
            variant='link'
            leftIcon={<BsFillArrowRightCircleFill />}
            ml='10'
          >
            {laststreamerUrl}
          </Button>
        )} */}

      <Menu>
        <MenuButton
          // py={2}
          // p={2}
          // mb={4}
          // transition='all 0.3s'
          _focus={{ boxShadow: "none", outline: "none" }}
        >
          <HStack>
            <VStack
              display={{ base: "none", md: "flex" }}
              alignItems='flex-start'
              spacing='1px'
              pl='10px'
            >
              <Text fontSize='lg' fontWeight='bold' mx='0'>
                {currentUserProfile?.displayName || "no_name"}
              </Text>
              {/* <Text fontSize='xs' color='gray.600'>
                  Admin
                </Text> */}
            </VStack>
            <Box>
              <ChevronDownIcon />
            </Box>
            <MyAvatar
              size='md'
              borderWidth='2px'
              borderColor='transparent'
              src={currentUserProfile?.photoURL || "/assets/user.png"}
              ml='10px'
            />
          </HStack>
        </MenuButton>
        <MenuList
          mt='-3px'
          mr='-10px'
          overflow={"hidden"}
          pt='20px'
          boxShadow='xs'
          bg={bg}
          // fontSize={"xs"}
          borderRadius='0px'
          borderLeftRadius='20px'
          borderRight={"0"}
          borderColor={borderColor}
        >
          {currentUserProfile?.url && (
            <>
              <MenuItem as={Link} to={`/${currentUserProfile?.url}`}>
                My Streamer Homepage
              </MenuItem>
              <MenuItem as={Link} to={`/dashboard/${currentUserProfile?.url}/`}>
                Dashboard
              </MenuItem>
              <MenuItem as={Link} to={`/streamersettings`}>
                Streamer Settings
              </MenuItem>
              <MenuDivider />
            </>
          )}

          <MenuItem as={Link} to={`/profile/${currentUserProfile?.id}`}>
            Profile
          </MenuItem>
          <MenuItem as={Link} to={`/settings`}>
            Settings
          </MenuItem>

          <MenuDivider />
          <MenuItem as={Link} to={`/logout`}>
            Logout
          </MenuItem>
        </MenuList>
      </Menu>
    </HStack>

    // <Menu.Item position='right'>
    //   <Image
    //     avatar
    //     spaced='right'
    //     src={currentUserProfile?.photoURL || "/assets/user.png"}
    //   />
    //   <Dropdown pointing='top left' text={currentUserProfile?.displayName}>
    //     <DropdownMenu>
    //       <Dropdown.Item
    //         as={Link}
    //         to='/createEvent'
    //         text='Create Event'
    //         icon='plus'
    //       />
    //       <Dropdown.Item
    //         as={Link}
    //         to={`/profile/${currentUserProfile?.id}`}
    //         text='My Profile'
    //         icon='user'
    //       />
    //       <Dropdown.Item
    //         as={Link}
    //         to='/account'
    //         text='My Account'
    //         icon='settings'
    //       />
    //       <Dropdown.Item onClick={handleSignOut} text='Signout' icon='power' />
    //     </DropdownMenu>
    //   </Dropdown>
    // </Menu.Item>
  );
}
