import { Button, ButtonGroup, IconButton } from "@chakra-ui/react";
import { Box } from "@chakra-ui/layout";
import React, { useState } from "react";
import { copyTextToClipboard } from "../../../app/common/util/util";

import { CheckIcon, CopyIcon } from "@chakra-ui/icons";

export default function SpoilerValue({ value, alwaysVisible = false }) {
  const [show, setShow] = useState(false);
  const [copied, setCopied] = useState(false);

  function handleShow() {
    setShow(!show);
  }
  function handleCopy(value) {
    copyTextToClipboard(value);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  }

  return (
    <Box>
      {(show || alwaysVisible) && (
        <Box>
          {value}{" "}
          <IconButton
            onClick={() => handleCopy(value)}
            variant='outline'
            size='xs'
            icon={copied ? <CheckIcon color='green' /> : <CopyIcon />}
          />
        </Box>
      )}
      {!show && !alwaysVisible && (
        <Box>
          <ButtonGroup variant='outline' size='xs' spacing='1'>
            <Button onClick={() => handleCopy(value)} pl='10px'>
              Copy to clipboard
              <Box ml='1' w='10px'>
                {copied && <CheckIcon color='green' />}
              </Box>
            </Button>
            <Button onClick={() => handleShow()}>Show</Button>
          </ButtonGroup>
        </Box>
      )}
    </Box>
  );
}
