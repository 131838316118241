export const ButtonStyles = {
  // Styles for the base style
  baseStyle: {
    _focus: { boxShadow: "none" },
    maxWidth: "300px",
  },
  // Styles for the size variations
  sizes: {},
  // Styles for the visual style variations
  variants: {
    primary: (props) => ({
      bg: "primary",
      color: "white",
      _hover: {
        bg: "primaryHover",
        textDecoration: "None",
        _disabled: {
          bg: "primary",
          color: "white",
        },
      },
    }),
    secondary: (props) => ({
      bg: "secondary",
      color: "white",
    }),
    passive: (props) => ({
      bg: "gray.600",
      color: "white",
      _hover: {
        bg: "gray.600",
      },
    }),
    warning: (props) => ({
      bg: "darkred",
      color: "white",
      _hover: {
        bg: "red",
      },
    }),
    primaryOutline: (props) => ({
      bg: "transparent",
      border: "1px solid",
      borderColor: "primary",
      color: "primary",
      _hover: {
        boxShadow: "md",
      },
    }),
    google: (props) => ({
      bg: "google",
      color: "white",
      _hover: {
        bg: "googleHover",
      },
    }),
    twitch: (props) => ({
      bg: "twitch",
      color: "white",
      _hover: {
        bg: "twitchHover",
      },
    }),
    discord: (props) => ({
      bg: "discord",
      color: "white",
      _hover: {
        bg: "discordHover",
      },
    }),
  },
  // The default `size` or `variant` values
  defaultProps: {},
};
