import React from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import UnauthModal from "../../features/auth/UnauthModal";

const ProtectedRoutes = (props) => {
  const { authenticated } = useSelector((state) => state.auth);

  return authenticated ? <Outlet /> : <UnauthModal {...props} />;
};

export default ProtectedRoutes;
