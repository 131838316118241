import React, { useEffect, useState } from "react";

export function loadPaypalDonationButton(callback) {
  const existingScript = document.getElementById("paypaldonate");

  if (!existingScript) {
    const script = document.createElement("script");
    script.src = "https://www.paypalobjects.com/donate/sdk/donate-sdk.js";
    script.id = "paypaldonate";
    document.body.appendChild(script);
    script.onload = () => {
      if (callback) callback();
    };
  }
  if (existingScript && callback) callback();
}

export default function PaypalDonateButton() {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    loadPaypalDonationButton(() => {
      setLoaded(true);
    });
  }, []);

  useEffect(() => {
    if (loaded) {
      // <-- only load form if script is loaded
      setTimeout(() => {
        if (
          document.getElementById("donate-button") !== null &&
          document.getElementById("paypaldonate") !== null &&
          window.PayPal !== undefined
        ) {
          document.getElementById("donate-button").innerHTML = "";
          window["PayPal"]["Donation"]
            ["Button"]({
              env: "production",
              hosted_button_id: "2EQ8Z4WYYLFV8",
              image: {
                src: "https://pics.paypal.com/00/s/MDk1YjVhNjYtNTJjZi00Y2NhLTljZGUtYjNhNWJiNDhlZjM3/file.PNG",
                alt: "Donate with PayPal button",
                title: "PayPal - The safer, easier way to pay online!",
              },
            })
            .render("#donate-button");
        }
      }, 0);
    }
  }, [loaded]);

  return (
    <div id='donate-button-container'>
      <div id='donate-button'></div>
    </div>
  );
}
