import React, { useRef } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

export default function PhotoWidgetCropper({ setImage, imagePreview }) {
  const cropperRef = useRef(null);

  const onCrop = () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;

    cropper.getCroppedCanvas({ width: 400, height: 400 }).toBlob(
      (blob) => {
        setImage(blob);
      },
      "image/jpeg",
      0.9
    );
  };

  return (
    <Cropper
      src={imagePreview}
      style={{ height: "auto", width: "100%" }}
      // Cropper.js options

      // minContainerWidth='160'
      // minContainerHeight='160'
      initialAspectRatio={1}
      aspectRatio={1}
      guides={false}
      preview='.img-preview'
      viewMode={1}
      dragMode='move'
      scalable={true}
      cropBoxMovable={true}
      cropBoxResizable={true}
      crop={onCrop}
      ref={cropperRef}
    />
  );
}
