import {
  child,
  get,
  getDatabase,
  push,
  ref,
  remove,
  set,
  update,
} from "firebase/database";
import {
  deleteObject,
  getStorage,
  ref as sRef,
  uploadBytesResumable,
} from "firebase/storage";
import {
  GoogleAuthProvider,
  OAuthProvider,
  createUserWithEmailAndPassword,
  getAuth,
  linkWithPopup,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  unlink,
  updateEmail,
  updatePassword,
  updateProfile,
} from "firebase/auth";

import firebase from "../config/firebase";
import {
  setUserProfileData,
  setUserProfileDataGoogle,
  setUserProfileDataTwitch,
} from "./firestoreService";

export function firebaseObjectToArray(snapshot) {
  if (snapshot) {
    return Object.entries(snapshot).map((e) =>
      Object.assign({}, e[1], { id: e[0] })
    );
  }
}

export function signInWithEmail(creds) {
  const auth = getAuth();

  return signInWithEmailAndPassword(auth, creds.email, creds.password);

  // Firebase 8 stuff
  // return firebase
  //   .auth()
  //   .signInWithEmailAndPassword(creds.email, creds.password);
}

export function resetPassword(creds) {
  const auth = getAuth();
  sendPasswordResetEmail(auth, creds.email);

  // Firebase 8 stuff
  // return firebase.auth().sendPasswordResetEmail(creds.email);
}

export function signOutFirebase() {
  const auth = getAuth();
  return signOut(auth);

  // Firebase 8 stuff
  // return firebase.auth().signOut();
}

export async function getUserAuthToken() {
  // const token = await firebase.auth().currentUser.getIdToken(true);
  const auth = getAuth();

  const tokenResult = await auth.currentUser.getIdTokenResult(true);

  // console.log("authTime", tokenResult.authTime);
  // console.log("issuedAtTime", tokenResult.issuedAtTime);
  // console.log("expirationTime", tokenResult.expirationTime);
  // console.log("token", tokenResult.token);
  // console.log("signInProvider", tokenResult.signInProvider);

  return tokenResult.token;
}

export async function registerInFirebase(creds, laststreamerUid) {
  try {
    const auth = getAuth();
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      creds.email,
      creds.password
    );
    // Signed up
    const user = userCredential.user;
    await updateProfile(auth.currentUser, {
      displayName: creds.displayName,
    });
    await setUserProfileData(user);
    return;
  } catch (error) {
    throw error;
  }

  // Firebase 8 stuff
  //   const result = await firebase
  //     .auth()
  //     .createUserWithEmailAndPassword(creds.email, creds.password);
  //   await result.user.updateProfile({ displayName: creds.displayName });
  //   return await setUserProfileData(result.user);
  // } catch (error) {
  //   throw error;
  // }
}

export async function socialUnlink(selectedProvider) {
  const auth = getAuth();
  unlink(auth.currentUser, selectedProvider).then(() => {
    // Auth provider unlinked from account
    // ...
  });
  // Firebase 8 stuff
  // const user = firebase.auth().currentUser;
  // return user.unlink(selectedProvider);
}

export async function socialLinkPassword(values) {
  const user = firebase.auth().currentUser;
  var credential = firebase.auth.EmailAuthProvider.credential(
    values.email,
    values.password
  );
  return user.linkWithCredential(credential);
  // .then((usercred) => {
  //   var user = usercred.user;
  //   console.log("Account linking success", user);
  // })
  // .catch((error) => {
  //   console.log("Account linking error", error);
  // });
}

export async function socialLink(selectedProvider) {
  const auth = getAuth();

  let provider;

  if (selectedProvider === "twitch") {
    provider = new OAuthProvider("oidc.twitch");

    // Firebase 8 stuff
    // provider = new firebase.auth.OAuthProvider("oidc.twitch");
    provider.addScope("user:read:email");
    // provider.addScope("channel:read:subscriptions");
    // provider.addScope("user:read:subscriptions");
    provider.addScope("openid");
    provider.setCustomParameters({
      claims: JSON.stringify({
        id_token: {
          preferred_username: null,
          email: null,
          email_verified: null,
          picture: null,
        },
      }),
    });
  }

  if (selectedProvider === "discord") {
    // Firebase 8 stuff
    // provider = new firebase.auth.OAuthProvider("oidc.discord");
    provider = new OAuthProvider("oidc.discord");
  }

  if (selectedProvider === "google") {
    // Firebase 8 stuff
    // provider = new firebase.auth.GoogleAuthProvider();
    provider = new GoogleAuthProvider();
  }

  return linkWithPopup(auth.currentUser, provider);
  // return user.linkWithPopup(provider);
}

function isNewUser(user) {
  return user.metadata.lastLoginAt - user.metadata.createdAt < 100;
}

export async function socialLogin(selectedProvider) {
  let provider;
  const auth = getAuth();

  if (selectedProvider === "twitch") {
    // OIDC Google Identity Platform. Using a popup.
    // provider = new firebase.auth.OAuthProvider("oidc.twitch");
    const provider = new OAuthProvider("oidc.twitch");
    provider.addScope("user:read:email");
    // provider.addScope("channel:read:subscriptions");
    // provider.addScope("user:read:subscriptions");
    provider.addScope("openid");
    provider.setCustomParameters({
      claims: JSON.stringify({
        id_token: {
          preferred_username: null,
          email: null,
          email_verified: null,
          picture: null,
        },
      }),
    });

    signInWithPopup(auth, provider)
      .then((result) => {
        console.log(result);

        if (isNewUser(result.user)) {
          setUserProfileDataTwitch(result.user);
        }
      })
      .catch((error) => {
        console.log(error.message);
      });

    // Firebase 8 stuff
    // firebase
    //   .auth()
    //   .signInWithPopup(provider)
    //   .then(function (result) {
    //     // var accessToken = result.credential.accessToken;
    //     // console.log(accessToken);

    //     if (result.additionalUserInfo.isNewUser) {
    //       setUserProfileDataTwitch(
    //         result.user,
    //         result.additionalUserInfo.profile
    //       );
    //     }
    //   });
  } else if (selectedProvider === "discord") {
    // OIDC Google Identity Platform. Using a popup.
    // provider = new firebase.auth.OAuthProvider("oidc.discord");
    // provider.addScope("identify");
    // provider.addScope("email");
    // firebase
    //   .auth()
    //   .signInWithPopup(provider)
    //   .then(function (result) {
    // console.log(result);
    // if (result.additionalUserInfo.isNewUser) {
    //   setUserProfileDataDiscord(
    //     result.user,
    //     result.additionalUserInfo.profile
    //   );
    // }
    // });
  } else {
    if (selectedProvider === "google") {
      // provider = new firebase.auth.GoogleAuthProvider();
      provider = new GoogleAuthProvider();
    }

    // let result = await firebase.auth().signInWithPopup(provider);
    signInWithPopup(auth, provider)
      .then((result) => {
        console.log(result);

        if (isNewUser(result.user)) {
          setUserProfileDataGoogle(result.user);
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  }
}

export function updateUserEmail(creds) {
  const auth = getAuth();
  const user = auth.currentUser;

  return updateEmail(user, creds.email);

  // Firebase 8 stuff
  // const user = firebase.auth().currentUser;
  // return user.updateEmail(creds.email);
}

export function updateUserPassword(creds) {
  const auth = getAuth();
  const user = auth.currentUser;

  return updatePassword(user, creds.newPassword1);

  // Firebase 8 stuff
  // const user = firebase.auth().currentUser;
  // return user.updatePassword(creds.newPassword1);
}

export function uploadToFirebaseStorage(file, filename) {
  const auth = getAuth();
  const user = auth.currentUser;

  const storage = getStorage();
  const storageRef = sRef(storage, `user_images/${user.uid}/${filename}`);

  return uploadBytesResumable(storageRef, file);
  // const storageRef = firebase.storage().ref();
  // return storageRef.child(`user_images/${user.uid}/${filename}`).put(file);
}

export function deleteFromFirebaseStorage(filename) {
  const auth = getAuth();
  const userUid = auth.currentUser.uid;
  // const userUid = firebase.auth().currentUser.uid;

  // const storageRef = firebase.storage().ref();
  // const photoRef = storageRef.child(`user_images/${userUid}/${filename}`);

  const storage = getStorage();
  const photoRef = sRef(storage, `user_images/${userUid}/${filename}`);

  return deleteObject(photoRef);
}

// export function addEventChatComment(eventId, values) {
//   const user = firebase.auth().currentUser;
//   const newComment = {
//     displayName: user.displayName,
//     photoURL: user.photoURL,
//     uid: user.uid,
//     text: values.comment,
//     date: Date.now(),
//     parentId: values.parentId,
//   };
//   return firebase.database().ref(`chat/${eventId}`).push(newComment);
// }

// export function getEventChatRef(eventId) {
//   return firebase.database().ref(`chat/${eventId}`).orderByKey();
// }

// export function getUserFeedRef() {
//   const user = firebase.auth().currentUser;
//   return firebase
//     .database()
//     .ref(`posts/${user.uid}`)
//     .orderByKey()
//     .limitToLast(5);
// }

export function deleteLobbyFromFirebase(lobbyId) {
  const db = getDatabase();
  return remove(ref(db, `lobby/${lobbyId}`));
  // Firebas 8 stuff
  // return firebase.database().ref(`lobby/${lobbyId}`).remove();
}

export async function getNewLobbyKeyFirebase() {
  const db = getDatabase();
  return push(child(ref(db), `lobby`)).key;
}

export function addLobbyToFirebase(values, newLobbyId) {
  // Firebase 8 stuff
  // const user = firebase.auth().currentUser;
  const auth = getAuth();
  const user = auth.currentUser;
  const db = getDatabase();

  const newValues = {
    ...values,
    status: "open",
    streamerId: user.uid,
    created: Date.now(),
    gameinprogress: "",
  };

  // const newKey = push(child(ref(db), `lobby`)).key;

  return set(ref(db, `lobby/${newLobbyId}`), newValues);

  // Firebase 8 stuff
  // // console.log(newValues);
  // return firebase.database().ref(`lobby`).push(newValues);
}

export async function updateLobbyInFirebase(lobbyId, values) {
  if (!lobbyId || lobbyId === undefined) return;

  const db = getDatabase();
  return update(ref(db, `lobby/${lobbyId}`), values);

  // Firebase 8 stuff
  // return firebase.database().ref(`lobby/${lobbyId}`).update(values);
}

export async function addLobbyQuizUserAnswer({ lobbyId, question, answer }) {
  const auth = getAuth();
  const user = auth.currentUser;
  const db = getDatabase();

  // Firebase 8 stuff
  // const user = firebase.auth().currentUser;

  if (!lobbyId || lobbyId === undefined) return;
  const quizvalues = {
    answer: answer,
    created: Date.now(),
  };

  return set(
    ref(db, `quiz/${lobbyId}/answers/${question}/${user.uid}/`),
    quizvalues
  );

  // Firebase 8 stuff
  // return firebase
  //   .database()
  //   .ref(`quiz/${lobbyId}/answers/${question}/${user.uid}/`)
  //   .set(quizvalues);
}

export async function endLobbyQuizInFirebase(lobbyId) {
  if (!lobbyId || lobbyId === undefined) return;

  return update(ref(getDatabase(), `lobby/${lobbyId}/quiz`), {
    status: "finished",
  });

  // Firebase 8 stuff
  // return firebase
  //   .database()
  //   .ref(`lobby/${lobbyId}/quiz`)
  //   .update({ status: "finished" });
}

export async function removeLobbyQuizInFirebase(lobbyId) {
  if (!lobbyId || lobbyId === undefined) return;

  const db = getDatabase();

  return set(ref(db, `lobby/${lobbyId}/quiz`), {});

  // Firebase 8 stuff
  // return firebase.database().ref(`lobby/${lobbyId}/quiz`).set({});
}

export async function setLobbyQuizUsersCanAnswerFirebase({
  lobbyId,
  usersCanAnswer,
}) {
  if (!lobbyId || lobbyId === undefined) return;

  return update(ref(getDatabase(), `lobby/${lobbyId}/quiz/currentQuestion`), {
    usersCanAnswer: usersCanAnswer,
  });

  // return firebase
  //   .database()
  //   .ref(`lobby/${lobbyId}/quiz/currentQuestion`)
  //   .update({
  //     usersCanAnswer: usersCanAnswer,
  //   });
}

export async function updateLobbyQuizInFirebase({ lobbyId, currentQuestion }) {
  if (!lobbyId || lobbyId === undefined) return;

  const db = getDatabase();

  const quizvalues = {
    updated: Date.now(),
    currentQuestion: currentQuestion,
  };

  return update(ref(db, `lobby/${lobbyId}/quiz`), quizvalues);

  // Firebase 8 stuff
  // return firebase.database().ref(`lobby/${lobbyId}/quiz`).update(quizvalues);
}

export async function createLobbyQuizInFirebase(lobbyId, values) {
  if (!lobbyId || lobbyId === undefined) return;

  const db = getDatabase();
  set(ref(db, `quiz/${lobbyId}`), { answers: {}, questions: values });

  // Firebase 8 stuff
  // firebase
  //   .database()
  //   .ref(`quiz/${lobbyId}`)
  //   .set({ answers: {}, questions: values });

  const quizvalues = {
    status: "in_progress",
    created: Date.now(),
    updated: Date.now(),
    currentQuestion: { index: -1, maxIndex: values.length - 1 },
  };

  return set(ref(db, `lobby/${lobbyId}/quiz`), quizvalues);

  // Firebase 8 stuff
  // return firebase.database().ref(`lobby/${lobbyId}/quiz`).set(quizvalues);
}

export async function updateLobbySelectedUsersBulkInFirebase(lobbyId, teams) {
  if (!lobbyId || lobbyId === undefined) return;
  if (!teams || teams === undefined) return;

  const db = getDatabase();
  let newData = {};

  teams.map((team, tindex) => {
    team.map((user, sindex) => {
      newData[`/lobby/${lobbyId}/selectedUsers/${tindex}/${sindex}`] = user;
      if (user.userId !== "" && !(tindex === 0 && sindex === 0))
        newData[`/lobby/${lobbyId}/users/${user.id}/status`] = "selected";
      return true;
    });
    return true;
  });

  return update(ref(db), newData);
  // FIrebase 8 stuff
  // return firebase.database().ref(`lobby/${lobbyId}`).update(newData);
}

export async function updateLobbySelectedUsersInFirebase(
  lobbyId,
  teamNum,
  slotNum,
  user
) {
  if (!lobbyId || lobbyId === undefined) return;
  if (!teamNum || teamNum === undefined) return;
  if (!slotNum || slotNum === undefined) return;

  const db = getDatabase();

  let newData = {};
  newData[`/lobby/${lobbyId}/selectedUsers/${teamNum}/${slotNum}`] = user;
  newData[`/lobby/${lobbyId}/users/${user.id}/status`] = "selected";

  return update(ref(db), newData);
  // Firebase 8 stuff
  // return firebase.database().ref(`lobby/${lobbyId}`).update(newData);
}

export async function updateLobbyUserStatusInFirebase(lobbyId, userId, status) {
  if (!lobbyId || lobbyId === undefined) return;
  if (!userId || userId === undefined) return;

  let updates = {};
  updates["status"] = status;

  const db = getDatabase();

  return update(ref(db), updates);
  // Firebase 8 stuff
  // return firebase
  //   .database()
  //   .ref(`lobby/${lobbyId}/users/${userId}`)
  //   .update(updates);
}

export async function updateLobbyUsersBulkStatusInFirebase(
  lobbyId,
  users,
  status
) {
  if (!lobbyId || lobbyId === undefined) return;
  if (!users || users === undefined) return;
  let updates = {};

  const db = getDatabase();

  users.map((doc) => {
    updates["users/" + doc.id + "/status"] = status;
    return true;
  });

  return update(ref(db), updates);
  // Firebase 8 stuff
  // return firebase.database().ref(`lobby/${lobbyId}`).update(updates);
}

export function updateLobbyAllUserStatusInFirebase(lobbyId, status) {
  if (!lobbyId || lobbyId === undefined) return;
  try {
    let updates = {};
    // updates["status"] = "in_progress";

    const dbRef = ref(getDatabase());
    get(child(dbRef, `lobby/${lobbyId}`))
      // Firebase 8 stuff
      // firebase
      // .database()
      // .ref(`lobby/${lobbyId}`)
      // .once("value")
      .then((snapshot) => {
        const lobby = snapshot.val();
        const users = firebaseObjectToArray(lobby.users);
        const selectedUsers = firebaseObjectToArray(lobby.selectedUsers);

        selectedUsers.map((team) => {
          Object.entries(team).map(([slotkey, slot]) => {
            if (parseInt(team.id) === 0 && parseInt(slotkey) === 0) {
              // is streamer / host, do nothing
            } else {
              if (slotkey !== "id") {
                updates[
                  `/lobby/${lobbyId}/selectedUsers/${team.id}/${slotkey}`
                ] = {
                  userId: "",
                  displayName: "",
                };
              }
            }
            return true;
          });
          return true;
        });

        users?.map((doc) => {
          updates[`/lobby/${lobbyId}/users/${doc.id}/status`] = status;
          return true;
        });
      })
      .then(() => {
        // console.log(updates);
        update(dbRef, updates);
        // Firebase 8 stuff
        // firebase.database().ref(`lobby/${lobbyId}`).update(updates);
      });
  } catch (error) {
    throw error;
  }
}

export async function getLobbyFromFirebase(lobbyId) {
  // Firebase 8 stuff
  // const dbRef = firebase.database().ref(`lobby/${lobbyId}`);

  // let data;
  const dbRef = ref(getDatabase());
  get(child(dbRef, `lobby/${lobbyId}`))
    .then((snapshot) => {
      if (snapshot.exists()) {
        console.log("snapshot.exists");
        console.log(snapshot.val());
        // data =
        return snapshot.val();
      } else {
        console.log("No data available");
      }
    })
    .catch((error) => {
      console.error(error);
    });

  // Firebase 8 stuff
  // await dbRef
  //   .get()
  //   .then((snapshot) => {
  //     if (snapshot.exists()) {
  //       data = snapshot.val();
  //     } else {
  //       console.log("No data available");
  //     }
  //   })
  //   .catch((error) => {
  //     console.error(error);
  //   });
}

export function listenToQuizUserAnswerFromFirebase(
  lobbyId,
  questionId,
  userId
) {
  const database = getDatabase();
  return ref(database, `quiz/${lobbyId}/answers/${questionId}/${userId}/`);

  // Firebase 8 stuff
  // return firebase
  //   .database()
  //   .ref(`quiz/${lobbyId}/answers/${questionId}/${userId}/`);
}

export function listenToQuizFromFirebase(lobbyId) {
  const database = getDatabase();
  return ref(database, `quiz/${lobbyId}`);
  // Firebase 8 stuff
  // return firebase.database().ref(`quiz/${lobbyId}`);
}

export function listenToLobbyFromFirebase(lobbyId) {
  const database = getDatabase();
  return ref(database, `lobby/${lobbyId}`);
  // Firebase 8 stuff
  // return database.ref(`lobby/${lobbyId}`);
}

export function joinLobbyInFirebase(lobbyId, userProfile, stats) {
  // Firebase 8 stuff
  // const lobbyRef = firebase.database().ref(`lobby/${lobbyId}`);
  const db = getDatabase();

  let numUsersInQueue;
  let maxQueueSize;

  // const getMaxQueueSize = async () => {
  //   await get(child(dbRef, `lobby/${lobbyId}`))
  //     .then((snapshot) => {
  //       if (snapshot.exists()) {
  //         maxQueueSize =
  //           snapshot.val().maxQueueSize !== undefined
  //             ? snapshot.val().maxQueueSize
  //             : process.env.REACT_APP_MAXQUEUESIZE_DEFAULT;

  //         numUsersInQueue = Object.keys(snapshot.val().users).length
  //           ? Object.keys(snapshot.val().users).length
  //           : 0;
  //       } else {
  //         console.log("No data available");
  //       }
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // };

  // getMaxQueueSize();

  // Firebase 8 stuff
  // lobbyRef.once("value", (data) => {
  //   maxQueueSize = data.val().maxQueueSize
  //     ? data.val().maxQueueSize
  //     : process.env.REACT_APP_MAXQUEUESIZE_DEFAULT;

  //   numUsersInQueue = data.val().users
  //     ? Object.keys(data.val().users).length
  //     : 0;
  // });

  if (numUsersInQueue >= maxQueueSize)
    throw new Error("Maxiumum number of users have joined this lobby.");

  const values = {
    joined: Date.now(),
    userId: userProfile.id,
    displayName: userProfile.displayName,
    status: "joined",
    photoURL: userProfile.photoURL,
    photoURLsmall: userProfile.photoURLsmall ? userProfile.photoURLsmall : null,
    hometown: userProfile.hometown ? userProfile.hometown : null,
    accounts: userProfile.accounts ? userProfile.accounts : null,
    accountsKeys: userProfile.accountsKeys ? userProfile.accountsKeys : null,
    stats: { ...stats, firstJoin: Date.parse(stats.firstJoin) },
  };

  // Get a key for a new Post.
  const newKey = push(child(ref(db), `lobby/${lobbyId}/users`)).key;

  return set(ref(db, `lobby/${lobbyId}/users/${newKey}`), values);
  // return firebase.database().ref(`lobby/${lobbyId}/users`).push(values);
}

export function leaveLobbyInFirebase(lobbyId, userLobbyId) {
  if (!userLobbyId) return;

  const db = getDatabase();
  return set(ref(db, `lobby/${lobbyId}/users/${userLobbyId}`), null);

  // Firebase 8 stuff
  // return firebase
  //   .database()
  //   .ref(`lobby/${lobbyId}/users/${userLobbyId}`)
  //   .remove();
}
