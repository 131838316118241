import { SIGN_IN_USER, SIGN_OUT_USER, RELOAD_USER } from "./authConstants";
import { getAuth, onIdTokenChanged } from "firebase/auth";
import { onSnapshot } from "firebase/firestore";
import { APP_LOADED } from "../../app/async/asyncReducer";
import {
  dataFromSnapshot,
  getUserProfile,
} from "../../app/firestore/firestoreService";
import { listenToCurrentUserProfile } from "../profiles/profileActions";

export function reloadUser(user) {
  return {
    type: RELOAD_USER,
    payload: user,
  };
}

export function signInUser(user) {
  return {
    type: SIGN_IN_USER,
    payload: user,
  };
}

export function signOutUser() {
  return {
    type: SIGN_OUT_USER,
  };
}

// export function saveAuthTokens(selectedProvider, result) {
//   return function (dispatch) {
//     // Get the OAuth access token and ID Token

//     if (selectedProvider === "twitch") {
//       console.log(selectedProvider);
//       console.log(result);

//       // const credential = OAuthProvider.credentialFromResult(result);
//       const accessToken = result.idToken;
//       // // const idToken = credential.idToken;
//       // console.log(credential);
//       // console.log(accessToken);
//       // console.log("HELLO WORLD");
//     }
//   };
// }

export function verifyAuth() {
  const auth = getAuth();
  return function (dispatch) {
    return onIdTokenChanged(auth, (user) => {
      if (user) {
        dispatch(signInUser(user));
        const profileRef = getUserProfile(user.uid);

        onSnapshot(profileRef, (doc) => {
          dispatch(listenToCurrentUserProfile(dataFromSnapshot(doc)));
          dispatch({ type: APP_LOADED });
        });
        // Firebase 8 stuff
        // profileRef.onSnapshot((snapshot) => {
        // dispatch(listenToCurrentUserProfile(dataFromSnapshot(snapshot)));
        // dispatch({ type: APP_LOADED });
        // });
      } else {
        dispatch(signOutUser());
        dispatch({ type: APP_LOADED });
      }
    });
  };
}
