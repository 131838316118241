import { Outlet } from "react-router-dom";
import NavBar from "../../features/nav/NavBar";

export default function LayoutWithNavbar({ children }) {
  return (
    <>
      <NavBar />
      <Outlet />
    </>
  );
}
