import { Button } from "@chakra-ui/react";
import { Image } from "@chakra-ui/image";
import {
  Box,
  Center,
  Heading,
  HStack,
  Link,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/layout";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as ReactLink } from "react-router-dom";
import ErrorComponent from "../../../app/common/errors/ErrorCompentent";
import Vcontainer from "../../../app/components/Vcontainer/Vcontainer";

import {
  getStreamerProfile,
  setUserFirstStreamer,
  updateUserProfile,
} from "../../../app/firestore/firestoreService";
import useFirestoreDoc from "../../../app/hooks/useFirestoreDoc";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import LobbyDetailed from "../../lobby/lobbyDetailed/LobbyDetailed";
import { listenToStreamerProfile } from "../profileActions";

export default function StreamerPage({ streamerUid, lobbyId }) {
  const dispatch = useDispatch();
  const { loading, error } = useSelector((state) => state.async);
  const { streamerProfile } = useSelector((state) => state.profile);
  const { currentUserProfile } = useSelector((state) => state.profile);
  const { currentUser } = useSelector((state) => state.auth);

  // Set StreameerURL as last visited URL as new "home"
  useEffect(() => {
    if (streamerProfile?.url) {
      localStorage.setItem("laststreamerUrl", `/${streamerProfile?.url}`);
      localStorage.setItem("laststreamerUid", `/${streamerProfile?.id}`);
    }

    if (currentUserProfile && !currentUserProfile.firstStreamerUid)
      setUserFirstStreamer(streamerProfile?.id);
  }, [streamerProfile?.url, streamerProfile?.id, currentUserProfile]);

  // CurrentUser is marked being in a lobby but lobby is finished by now
  useEffect(() => {
    if (
      currentUserProfile?.joinedLobby !== null &&
      streamerProfile?.url === currentUserProfile?.joinedStreamerURL &&
      streamerProfile?.createdLobby !== currentUserProfile?.joinedLobby
    ) {
      try {
        updateUserProfile({
          joinedLobby: null,
          joinedStreamerURL: null,
        });
      } catch (error) {
        console.log(error.message);
      }
    }
  }, [
    streamerProfile?.url,
    currentUserProfile?.joinedStreamerURL,
    streamerProfile?.createdLobby,
    currentUserProfile?.joinedLobby,
  ]);

  useFirestoreDoc({
    query: () => getStreamerProfile(streamerUid),
    data: (profile) => dispatch(listenToStreamerProfile(profile)),
    deps: [dispatch, streamerUid],
    shouldExecute: streamerUid !== undefined && streamerUid !== false, // undefined === loaded yet, false === not a valid streamerURL / not found
  });

  // No StreamerUId
  if (!streamerUid) return <ErrorComponent errormsg='404 - not found' />;

  // Waiting for being loaded completely
  if ((loading && !streamerProfile) || (!streamerProfile && !error))
    return (
      <Vcontainer>
        <LoadingComponent content='Loading Streamer Data ...' />
      </Vcontainer>
    );

  if (!streamerProfile?.id) {
    return (
      <Vcontainer>Error loading streamerdata, streamer not found.</Vcontainer>
    );
  }

  return (
    <Vcontainer>
      <VStack w='full'>
        <Box>
          <Center>
            {currentUser &&
              streamerProfile?.id === currentUserProfile?.id &&
              !streamerProfile?.createdLobby && (
                <Button
                  as={ReactLink}
                  to='/createLobby'
                  size='lg'
                  variant='primary'
                  m='10'
                >
                  New Lobby
                </Button>
              )}
          </Center>
        </Box>

        <VStack w='full'>
          <Box w='full' textAlign='right' pr='10px'>
            {streamerProfile.twitchChannel && (
              <Link
                isexternal='true'
                target='_blank'
                href={`https://www.twitch.tv/${streamerProfile.twitchChannel}`}
              >
                twitch.tv/{streamerProfile.twitchChannel}
              </Link>
            )}
          </Box>

          <HStack w='full'>
            <Spacer />
            {!streamerProfile.createdLobby && (
              <Image
                src={streamerProfile.photoURL || "/assets/user.png"}
                w={100}
                p={2}
                borderRadius={100}
                float='left'
              />
            )}
            <Center>
              <Heading size='2xl'>
                Streamup{" "}
                <Box display={"inline"} opacity={"0.5"}>
                  with
                </Box>{" "}
                {`${streamerProfile.displayName}`}
              </Heading>
            </Center>
            <Spacer />
          </HStack>

          {!streamerProfile.createdLobby &&
            !lobbyId &&
            streamerProfile.streamer_description && (
              <Box
                fontSize='lg'
                // left='26px'
                position='relative'
                borderColor={"highlight"}
                // border='1px solid'
                p='30px'
                borderRadius='20px'
                bg='gray.900'
                width='100%'
                maxWidth='800px'
              >
                {streamerProfile.streamer_description}
              </Box>
            )}
        </VStack>

        <Box>
          <Center>
            {!streamerProfile.createdLobby &&
              !lobbyId &&
              streamerProfile.id !== currentUserProfile?.id && (
                <Text mt='10'>{`There's currently no active lobby. Wait for ${streamerProfile.displayName} to create one.`}</Text>
              )}
            {!streamerProfile.createdLobby &&
              !lobbyId &&
              streamerProfile.id === currentUserProfile?.id && (
                <Text mt='10'>
                  Hey {streamerProfile.displayName}, currently you don't have an
                  active lobby.{" "}
                  <Link as={ReactLink} to='/createLobby'>
                    Create one
                  </Link>
                  !
                </Text>
              )}
          </Center>
        </Box>
        <Box w='full' minHeight='400px'>
          {(streamerProfile.createdLobby || lobbyId) && (
            <LobbyDetailed
              lobbyId={lobbyId || streamerProfile.createdLobby}
              lobbyIdUrl={lobbyId}
              streamerProfile={streamerProfile}
            />
          )}
        </Box>
      </VStack>
    </Vcontainer>
  );
}
