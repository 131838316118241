import React, { useState } from "react";
import { useParams } from "react-router-dom";
import ErrorComponent from "../../../app/common/errors/ErrorCompentent";
import Vcontainer from "../../../app/components/Vcontainer/Vcontainer";
import { getStreamerUidByURL } from "../../../app/firestore/firestoreService";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import StreamerPage from "./StreamerPage";

export default function StreamerUrl() {
  const { url, lobbyId } = useParams();
  const [streamerUid, setstreamerUid] = useState(undefined);

  getStreamerUidByURL(url).then((uid) => {
    if (streamerUid !== uid) {
      setstreamerUid(uid);
    }
  });

  // NOT FOUND, INVALID URL
  if (streamerUid === undefined)
    return (
      <Vcontainer>
        <LoadingComponent content='Loading ...' />
      </Vcontainer>
    );

  // NOT FOUND, INVALID URL
  if (streamerUid === false)
    return <ErrorComponent errormsg='404 - not found!' />;

  return <StreamerPage streamerUid={streamerUid} lobbyId={lobbyId} />;
}
