import { Form, Formik } from "formik";
import React from "react";

import MyTextInput from "../../app/common/form/MyTextInput";
import * as Yup from "yup";
import { updateUserProfile } from "../../app/firestore/firestoreService";
import { FormControl } from "@chakra-ui/form-control";
import { Button, Checkbox } from "@chakra-ui/react";
import { Box, Text, Link, Heading, Stack } from "@chakra-ui/layout";
import { useToast } from "@chakra-ui/toast";
import { Link as ReactLink } from "react-router-dom";

import ModalWrapper from "../../app/common/modals/ModalWrapper";
import { closeModal } from "../../app/common/modals/modalReducer";
import { useDispatch } from "react-redux";

export default function DisplayNameForm({ currentUserProfile, noClose }) {
  const toast = useToast();
  const dispatch = useDispatch();

  const currentName =
    currentUserProfile.displayName === "Player1"
      ? ""
      : currentUserProfile.displayName;

  return (
    <ModalWrapper noClose={noClose}>
      <Box>
        <Formik
          validateOnBlur={false}
          initialValues={{
            displayName: currentName || "",
            legalAcced:
              currentUserProfile.legalVersionAcced >=
              process.env.REACT_APP_CURRENT_LEGAL_VER
                ? true
                : false,
          }}
          validationSchema={Yup.object({
            displayName: Yup.string().max(20).trim().required(),
            legalAcced: Yup.bool()
              .oneOf([true], "You must accept the terms and conditions")
              .required(),
          })}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              await updateUserProfile(values);
              dispatch(closeModal());
              toast({
                title: "Success",
                description: "Data has been updated",
                status: "success",
                isClosable: true,
              });
            } catch (error) {
              // toast.error(error.message);
              console.log(error.message);
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting, isValid, setFieldValue, values, dirty }) => (
            <Form>
              <FormControl>
                <Stack w='full'>
                  <Heading size='lg'>One more thing..</Heading>

                  {(!currentUserProfile.displayName?.trim() ||
                    currentUserProfile.displayName.length > 20 ||
                    currentUserProfile.displayName === "Player1") && (
                    <Box>
                      <b>What's your name?</b>
                      <Box fontSize={"sm"} opacity={"0.5"}>
                        (Will be visible to others)
                      </Box>

                      <MyTextInput tabIndex='1' name='displayName' label='' />
                    </Box>
                  )}
                  <Box>
                    <Checkbox
                      name={"legalAcced"}
                      id={"legalAcced"}
                      tabIndex='2'
                      spacing='20px'
                      ml={"10px"}
                      isChecked={values.legalAcced}
                      onChange={(e) =>
                        setFieldValue("legalAcced", e.target.checked)
                      }
                    >
                      I've read and accept the current{" "}
                      <Text display={"inline"} fontSize={"sm"} opacity={"0.5"}>
                        ({process.env.REACT_APP_CURRENT_LEGAL_DATE})
                      </Text>
                      <Box>
                        <Link as={ReactLink} to='/terms-of-use' target='_blank'>
                          Terms of Use
                        </Link>{" "}
                        and{" "}
                        <Link
                          as={ReactLink}
                          to='/privacy-policy'
                          target='_blank'
                        >
                          Privacy Policy
                        </Link>
                      </Box>
                    </Checkbox>
                  </Box>
                  <Box w='full' mt='30px'>
                    <Button
                      isLoading={isSubmitting}
                      isDisabled={isSubmitting || !isValid}
                      type='submit'
                      w='full'
                      id='id_submit'
                      tabIndex='3'
                      mt='30px'
                      variant='primary'
                    >
                      Save
                    </Button>
                  </Box>
                </Stack>
              </FormControl>
            </Form>
          )}
        </Formik>
      </Box>
    </ModalWrapper>
  );
}
