import { useColorModeValue } from "@chakra-ui/color-mode";
import { Box, Flex, Heading, Stack } from "@chakra-ui/layout";
import React from "react";
import RegisterForm from "./RegisterForm";
import LogoHorizontal from "../../app/components/Logo/LogoHorizontal";

export default function RegisterPage({ history }) {
  return (
    <>
      <Flex
        minH={"100vh"}
        align={"center"}
        justify={"center"}
        // bg={useColorModeValue("gray.50", "gray.800")}
      >
        <Stack spacing='8' mx={"auto"} maxW='full' py={12} px={6}>
          <Stack align={"center"}>
            <LogoHorizontal />
            <Heading>Create Account</Heading>
            {/* <Text fontSize={"lg"} color={"gray.600"}>
              to enjoy all of our cool <Link color={"blue.400"}>features</Link>{" "}
              ✌️
            </Text> */}
          </Stack>
          <Box
            borderRadius='20px'
            bg={useColorModeValue("white", "gray.900")}
            // boxShadow={"lg"}
            // minW={400}
            p={8}
          >
            <RegisterForm history={history} />
          </Box>
        </Stack>
      </Flex>
    </>
  );
}
