const baseStyle = (props) => ({
  dialog: {
    // maxWidth: ["95%", "95%", "95%"],
    // minWidth: "95%",
    // opacity: "90%",
  },
});

export const ModalStyles = {
  baseStyle,
};
