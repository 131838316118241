import React from "react";
// import { useDispatch } from "react-redux";
import { Button } from "@chakra-ui/react";
// import { closeModal } from "../../app/common/modals/modalReducer";
import { useSelector } from "react-redux";
import { socialLogin } from "../../app/firestore/firebaseService";
import { ImTwitch, ImGoogle } from "react-icons/im";
import { HStack } from "@chakra-ui/layout";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";

export default function SocialLogin({ rest }) {
  const navigate = useNavigate();
  const { prevLocation } = useSelector((state) => state.auth);
  const { currentUser } = useSelector((state) => state.auth);
  const location = useLocation();

  useEffect(() => {
    // If User is logged in successfully, forward to regular page
    const localPrevLocation = JSON.parse(localStorage.getItem("prevLocation"));

    if (currentUser?.uid) {
      if (prevLocation) {
        if (location.pathname !== prevLocation.pathname) navigate(prevLocation);
      } else if (localPrevLocation) {
        if (location.pathname !== localPrevLocation.pathname)
          navigate(localPrevLocation);
      } else {
        navigate("/settings");
      }
    }
  }, [navigate, location.pathname, prevLocation, currentUser]);

  function handleSocialLogin(provider) {
    socialLogin(provider);
  }

  return (
    <HStack justifyContent='center' {...rest}>
      <Button
        onClick={() => handleSocialLogin("google")}
        variant='google'
        leftIcon={<ImGoogle />}
      >
        Google
      </Button>
      <Button
        onClick={() => handleSocialLogin("twitch")}
        variant='twitch'
        leftIcon={<ImTwitch />}
      >
        Twitch
      </Button>
      {/* <Button
        onClick={() => handleSocialLogin("discord")}
        variant='discord'
        leftIcon={<RiDiscordFill />}
      >
        Discord
      </Button> */}
    </HStack>
  );
}
