import React from "react";
import * as Yup from "yup";
// import { addQuizItem } from "../../../app/firestore/firestoreService";
import { Box, HStack, Link } from "@chakra-ui/layout";
import { quizimportcategoryData } from "../../../app/api/quizimportcategoryData";
import { useToast } from "@chakra-ui/toast";
import { Form, Formik } from "formik";
import { FormControl } from "@chakra-ui/form-control";
import { Button } from "@chakra-ui/react";
import ModalWrapper from "../../../app/common/modals/ModalWrapper";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../app/common/modals/modalReducer";
import {
  asyncActionFinish,
  asyncActionStart,
} from "../../../app/async/asyncReducer";
import MySelectInput from "../../../app/common/form/MySelectInput";
import { getAllQuizQuestions } from "../../../app/api/googlespreadsheet";
import { addQuizItem } from "../../../app/firestore/firestoreService";

export default function StreamerSettingsQuizImportForm({
  currentUserProfile,
  item,
  answers,
  closeEdit,
}) {
  const toast = useToast();
  const dispatch = useDispatch();

  function handleCancel() {
    dispatch(closeModal());
  }

  async function getQuestionsFromGoogleSpreadsheet(cat) {
    return getAllQuizQuestions(cat);
  }

  if (!quizimportcategoryData) return <Box>Loading...</Box>;

  return (
    <ModalWrapper size='xl' header='Import Predefined Questions'>
      <Formik
        enableReinitialize
        initialValues={{
          category: quizimportcategoryData[0]["value"],
        }}
        validationSchema={Yup.object({
          category: Yup.string().max(40).trim().required(),
        })}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          try {
            dispatch(asyncActionStart());
            const category = values.category;

            const result = await getQuestionsFromGoogleSpreadsheet(
              values.category
            );

            for (const element of result.filter((e, index) => index > 0)) {
              const values = {
                question: element[0],
                answer1: element[1],
                answer2: element[2],
                answer3: element[3],
                answer4: element[4],
                category: quizimportcategoryData.filter(
                  (elem) => elem.value === category
                )[0].text,
                correctAnswer: parseInt(element[5]),
              };
              await addQuizItem(values);
            }

            dispatch(closeModal());
            dispatch(asyncActionFinish());
            toast({
              title: "Success",
              description: "Quiz Items have been imported",
              status: "success",
              isClosable: true,
            });
          } catch (error) {
            toast({
              title: "Error",
              description: error.message,
              status: "error",
              isClosable: true,
            });
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, isValid, dirty }) => (
          <Form key='quiz_item_edit'>
            <FormControl>
              {quizimportcategoryData && (
                <MySelectInput
                  name='category'
                  label='Category'
                  options={quizimportcategoryData}
                />
              )}

              <Box mt='40px' mb='20px'>
                You can{" "}
                <Link href='/previewimportquestions' target='_blank'>
                  preview the questions
                </Link>{" "}
                for each category.
              </Box>

              <HStack mt='40px'>
                <Button
                  isLoading={isSubmitting}
                  isDisabled={isSubmitting || !isValid}
                  type='submit'
                  variant='primary'
                >
                  Import
                </Button>
                <Button onClick={() => handleCancel()}>Cancel</Button>
              </HStack>
            </FormControl>
          </Form>
        )}
      </Formik>
    </ModalWrapper>
  );
}
