import { Box, VStack, WrapItem } from "@chakra-ui/layout";
import React from "react";
import { Link } from "react-router-dom";
import MyAvatar from "../../../app/components/MyAvatar/MyAvatar";

import { AiOutlineClose } from "react-icons/ai";

export default function LobbyListUser({ user, streamerProfile }) {
  return (
    <WrapItem
      as={Link}
      to={`/profile/${streamerProfile.url}/${user.userId}`}
      className={user.status === "vanishing" ? "thanosvanish" : ""}
      position='relative'
    >
      <VStack>
        <MyAvatar
          size='lg'
          src={user.photoURLsmall}
          name={user.displayName}
          opacity={user.status === "not_selected" ? 0.2 : 1}
          borderWidth='2px'
          borderStyle='solid'
          borderColor={user.status === "selected" ? "primary" : "gray.600"}
        />
        <Box position='absolute' top='0' left='8px'>
          {(user.status === "not_selected" || user.status === "removed") && (
            <AiOutlineClose size='50px' color='red' />
          )}
        </Box>

        {/* <Box>
          <Text size='sm'>{user.displayName}</Text>
        </Box> */}
      </VStack>
    </WrapItem>
  );
}
