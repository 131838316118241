import { useColorModeValue } from "@chakra-ui/color-mode";
import { Box, Flex, Spacer } from "@chakra-ui/layout";
import React from "react";

export default function Section({
  children,
  title,
  secondaryTitle,
  attached,
  padding,
  ...rest
}) {
  const color = useColorModeValue("white", "gray.300");
  const bordercolor = useColorModeValue("gray.700", "passive");
  const bg = useColorModeValue("white", "gray.900");

  return (
    <Box
      borderWidth='0px'
      borderTopWidth={attached ? "0" : "0px"}
      borderColor={bordercolor}
      bg={bg}
      // className='skew'
      position='relative'
      borderRadius='20px'
      // borderTopRadius={attached ? "0" : "0px"}
      {...rest}
    >
      <Box>
        {title && (
          <Flex
            className='skew'
            // borderTopRadius='0px'
            w='full'
            position='relative'
            borderTopRadius='20px'
            // left='-25px'
            bg={bordercolor}
            color={color}
          >
            <Box display='block' p='8px 24px'>
              {title}
            </Box>
            <Spacer />
            <Box className='unskew' display='block' p={2}>
              {secondaryTitle}
            </Box>
          </Flex>
        )}
        <Box p={padding ? padding : "2"}>{children}</Box>
      </Box>
    </Box>
  );
}
