import { configureStore } from "@reduxjs/toolkit";

import authReducer from "../../features/auth/authReducer";
import lobbyReducer from "../../features/lobby/lobbyReducer";
import profileReducer from "../../features/profiles/profileReducer";
// import testReducer from "../../features/sandbox/testReducer";
import QuizReducer from "../../features/selectors/quiz/QuizReducer";
import asyncReducer from "../async/asyncReducer";
import modalReducer from "../common/modals/modalReducer";
import { createRouterMiddleware } from "@lagunovsky/redux-react-router";
import { history } from "./history";
import { verifyAuth } from "../../features/auth/authActions";
import { createRouterReducer } from "@lagunovsky/redux-react-router";
import { SIGN_IN_USER } from "../../features/auth/authConstants";

const routerMiddleware = createRouterMiddleware(history);

// Automatically adds the thunk middleware and the Redux DevTools extension
export function configStore() {
  const store = configureStore({
    reducer: {
      router: createRouterReducer(history),
      // test: testReducer,
      lobby: lobbyReducer,
      modals: modalReducer,
      auth: authReducer,
      async: asyncReducer,
      profile: profileReducer,
      quiz: QuizReducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [
            SIGN_IN_USER,
            // ASYNC_ACTION_START,
            // LISTEN_TO_CURRENT_USER_PROFILE,
          ],
        },
      }).prepend(routerMiddleware),
  });
  store.dispatch(verifyAuth());

  return store;
}

// import { applyMiddleware, createStore } from "redux";
// import { composeWithDevTools } from "@redux-devtools/extension";
// import rootReducer from "./rootReducer";
// import { createRouterMiddleware } from "@lagunovsky/redux-react-router";
// import { thunk } from "redux-thunk";
// import { verifyAuth } from "../../features/auth/authActions";
// import { createBrowserHistory } from "history";

// export const history = createBrowserHistory();

// export function configureStore() {
//   const store = createStore(
//     rootReducer(history),
//     composeWithDevTools(applyMiddleware(createRouterMiddleware(history), thunk))
//   );

//   store.dispatch(verifyAuth());

//   return store;
// }
