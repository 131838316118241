import { Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import MyTextInput from "../../app/common/form/MyTextInput";
import { Button } from "@chakra-ui/react";

import { resetPassword } from "../../app/firestore/firebaseService";

import { HStack, Stack, StackDivider } from "@chakra-ui/layout";
import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { useNavigate } from "react-router-dom";
import { useToast } from "@chakra-ui/toast";

export default function RecoverPasswordForm() {
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();

  function handleCancel() {
    navigate("/login");
  }

  return (
    // <ModalWrapper size='mini' header='Register'>
    <Formik
      initialValues={{ email: "" }}
      validationSchema={Yup.object({
        email: Yup.string().required().email(),
      })}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        try {
          await resetPassword(values);

          setSubmitting(false);

          toast({
            title: "EMail sent!",
            description: "Check your mailbox and reset your password.",
            status: "success",
            isClosable: true,
          });
          navigate("/login");
        } catch (error) {
          switch (error.code) {
            case "auth/user-not-found":
              setErrors({ auth: "User not found" });
              break;
            default:
              setErrors({ auth: error.message });
          }

          setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting, isValid, dirty, errors }) => (
        <HStack>
          <Stack>
            <Form>
              <FormControl>
                <Stack spacing={5}>
                  <MyTextInput name='email' label='eMail' />

                  {errors.auth && (
                    <FormLabel
                      basic
                      color='warning'
                      style={{
                        marginBottom: 10,
                        top: "72px",
                      }}
                    >
                      {errors.auth}
                    </FormLabel>
                  )}
                  <StackDivider />
                  <Stack>
                    <Button
                      isLoading={isSubmitting}
                      isDisabled={!isValid || !dirty || isSubmitting}
                      type='submit'
                      variant='primary'
                      id='id_submit'
                      w={400}
                    >
                      Reset Password
                    </Button>
                    <Button onClick={handleCancel} variant='outline' w={100}>
                      Cancel
                    </Button>
                  </Stack>
                </Stack>
              </FormControl>
            </Form>
          </Stack>
        </HStack>
      )}
    </Formik>
    // {/* </ModalWrapper> */}
  );
}
