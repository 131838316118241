import { extendTheme } from "@chakra-ui/react";
import { ButtonStyles as Button } from "./components/ButtonStyles";
import { InputStyles as Input } from "./components/InputStyles";
import { FormLabelStyles as FormLabel } from "./components/FormLabelStyles";
import { HeadingStyles as Heading } from "./components/HeadingStyles";
import { LinkStyles as Link } from "./components/LinkStyles";
import { ModalStyles as Modal } from "./components/ModalStyles";
import { MenuStyles as Menu } from "./components/MenuStyles";

export const myNewTheme = extendTheme({
  config: {
    initialColorMode: "dark",
  },

  styles: {
    global: {
      // styles for the `body`
      body: {
        bg: "gray.800",
        color: "gray.300",
      },
      h1: {
        color: "white",
      },
      // styles for the `a`
      // a: {
      //   color: "teal.500",
      //   _hover: {
      //     textDecoration: "underline",
      //   },
      // },
    },
  },

  fonts: {
    heading: "Rajdhani",
    body: "Montserrat",
  },
  colors: {
    primary: "#ff780e",
    primaryHover: "rgb(255, 147, 62)",
    secondary: "#007C77",
    passive: "#444b57",
    highlight: "#f23f22",
    warning: "#FFC75F",
    danger: "#C34A36",
    google: "#4285F4",
    googleHover: "#5e96f4",
    twitch: "#6441A4",
    twitchHover: "#7156a4",
    discord: "rgb(88, 101, 242)",
    discordHover: "#6c78f2",
    modalBg: "#1d2634", // #2d3748
    gray: {
      800: "rgb(26, 32, 44)",
    },
  },

  components: {
    Button,
    Input,
    FormLabel,
    Heading,
    Link,
    Modal,
    Menu,
  },
});
