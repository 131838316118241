import { Box, Link, Text, Wrap } from "@chakra-ui/layout";
import React from "react";
import Section from "../../../app/components/section/Section";
import LobbyListUser from "./LobbyListUser";

export default function LobbyUsers({ selectedLobby, streamerProfile }) {
  const numUsersAll = selectedLobby?.users?.length;
  const numUsersAvalaible = selectedLobby?.users?.filter(
    (elem) => elem.status !== "not_selected"
  ).length;

  let lobbyStatusText;
  switch (selectedLobby.status) {
    case "open":
      lobbyStatusText = (
        <>
          Lobby is OPEN for joining! <div className='led green'></div>
        </>
      );
      break;
    case "all_selected":
      lobbyStatusText = (
        <>
          Players have been selected. <div className='led blue'></div>
        </>
      );

      break;
    case "in_progress":
      lobbyStatusText = (
        <>
          Lobby has started, no more joins. <div className='led blue'></div>
        </>
      );

      break;
    case "closed":
      lobbyStatusText = "Lobby is closed, no more users allowed";
      break;
    case "finished":
      lobbyStatusText = (
        <>
          Lobby is over & done. <div className='led red'></div>
        </>
      );
      break;
    default:
      lobbyStatusText = selectedLobby.status;
  }

  return (
    <Section
      mt='30px'
      title={
        numUsersAvalaible !== numUsersAll
          ? `Lobby (${numUsersAvalaible || 0}/${numUsersAll || 0})`
          : `Lobby (${numUsersAll || 0})`
      }
      secondaryTitle={lobbyStatusText}
      minHeight='250px'
      fontSize={["xs", "md"]}
    >
      <Box>
        {selectedLobby.users && (
          <Wrap name='lobby_all_users'>
            {selectedLobby.users
              .sort((a, b) => a.joined - b.joined)
              // .sort((a, b) =>
              //   selectedLobby.gameinprogress !== "" ||
              //   a.status === "not_selected"
              //     ? 1
              //     : -1
              // )
              .map((user) => (
                <LobbyListUser
                  key={user.id}
                  streamerProfile={streamerProfile}
                  user={user}
                  // fadeInUsers={true}
                />
              ))}
          </Wrap>
        )}
        {!selectedLobby.users && (
          <>
            <Text my='10' textAlign='center' opacity='0.3'>
              Waiting for users to join.
            </Text>
          </>
        )}
        <Text my='10' textAlign='center'>
          Share this link:
          <Link
            ml='10px'
            href={`${process.env.REACT_APP_SELF_DOMAIN_FULL_NICE}/${streamerProfile.url}`}
            isExternal
          >{`${process.env.REACT_APP_SELF_DOMAIN_NICE}/${streamerProfile.url}`}</Link>
        </Text>
      </Box>
    </Section>
  );
}
