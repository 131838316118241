import React from "react";
import { Spinner, HStack, Box } from "@chakra-ui/react";

export default function LoadingComponent({ content = "Loading ..." }) {
  return (
    <>
      <HStack
        justifyContent={"center"}
        margin={"auto"}
        height={"100vh"}
        p={"20px"}
      >
        <Spinner emptyColor={"passive"} color={"primary"} size='sm' />
        <Box textTransform='uppercase'>{content}</Box>
      </HStack>
    </>
  );
}
