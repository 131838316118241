import { Box, Heading, Text } from "@chakra-ui/layout";
import React, { useState } from "react";
import LobbySelectedUsers from "./LobbySelectedUsers";
import { format } from "date-fns";
import { CalendarIcon } from "@chakra-ui/icons";
import AfterMatchModal from "./AfterMatchModal";

export default function LobbyFinished({
  currentUserProfile,
  selectedLobby,
  streamerProfile,
}) {
  const [modalOpen, setModalOpen] = useState(true);
  return (
    <>
      {modalOpen && (
        <AfterMatchModal
          currentUserProfile={currentUserProfile}
          selectedLobby={selectedLobby}
          setModalOpen={setModalOpen}
          streamerProfile={streamerProfile}
        />
      )}
      <Box textAlign='center'>
        <Box>
          <CalendarIcon /> {format(selectedLobby.created, "MMMM d, yyyy hh:mm")}
          <Heading size='sm' m='20px'>
            This team has played, the lobby is closed.
          </Heading>
          <Text></Text>
        </Box>
        <LobbySelectedUsers selectedLobby={selectedLobby} />
      </Box>
    </>
  );
}
