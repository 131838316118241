import { Button, ButtonGroup } from "@chakra-ui/react";
import React from "react";
// import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { ImTwitch } from "react-icons/im";
import { socialLogin } from "../../app/firestore/firebaseService";
import { Box } from "@chakra-ui/react";

// import { openModal } from "../../app/common/modals/modalReducer";

export default function SignedOutMenu({ setAuthenticated }) {
  // const dispatch = useDispatch();
  return (
    <ButtonGroup pl='20px' pr='10px'>
      <Button
        onClick={() => socialLogin("twitch")}
        variant='twitch'
        leftIcon={<ImTwitch />}
        fontSize={["xs", "inherit"]}
        padding={["6px", "inherit"]}
      >
        Login <Box display={["none", "initial"]}>&#160; with Twitch</Box>
      </Button>
      <Button
        variant='outline'
        as={NavLink}
        to='/login'
        fontSize={["xs", "inherit"]}
        padding={["6px", "inherit"]}
        id='topmenu_login_register'
      >
        Login / Register
      </Button>
    </ButtonGroup>
  );
}
