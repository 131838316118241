import React, { Suspense } from "react";
import HomePage from "../../features/home/HomePage";
import ModalManager from "../common/modals/ModalManager";

import { Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import { useColorMode, useColorModeValue } from "@chakra-ui/color-mode";
import { Box } from "@chakra-ui/layout";
import { Image } from "@chakra-ui/image";
import { lazy } from "react";

import ErrorComponent from "../common/errors/ErrorCompentent";

import bgimg from "../../assets/gfx/logo_v1_icon_bw.svg";
import StreamerSettingsQuizImportPreview from "../../features/profiles/streamerSettings/StreamerSettingsQuizImportPreview";
// import CookieConsent from "react-cookie-consent";

import LoadingComponent from "./LoadingComponent";
import LoadingComponentSmall from "./LoadingComponentSmall";

import LoginPage from "../../features/auth/LoginPage";
import RegisterPage from "../../features/auth/RegisterPage";
import StreamerUrl from "../../features/profiles/streamerPage/StreamerUrl";
import LogoutPage from "../../features/auth/LogoutPage";
import Footer from "./Footer";
import RecoverPasswordPage from "../../features/auth/RecoverPasswordPage";
import LayoutWithNavbar from "./LayoutWithNavbar";
import ProtectedRoutes from "./ProtectedRoutes";

const ProfilePage = lazy(() =>
  import("../../features/profiles/profilePage/ProfilePage")
);
const SettingsPage = lazy(() =>
  import("../../features/profiles/settings/SettingsPage")
);
const DashboardPage = lazy(() => import("../../features/dashboard/Dashboard"));
const MasterdashPage = lazy(() =>
  import("../../features/dashboard/Masterdash")
);
const StreamerSettingsPage = lazy(() =>
  import("../../features/profiles/streamerSettings/StreamerSettingsPage")
);
const LegalText = lazy(() => import("../../features/legal/LegalText"));
const LobbyForm = lazy(() =>
  import("../../features/lobby/lobbyForm/LobbyForm")
);
const About = lazy(() => import("../../features/about/About"));

// import { Link } from "@chakra-ui/react";
// import { Link as ReactLink } from "react-router-dom";

// const About = lazy(() => import("../../features/about/About"));

function App() {
  // const { key } = useLocation();
  const { initialized } = useSelector((state) => state.async);
  const { colorMode, toggleColorMode } = useColorMode();
  const bg = useColorModeValue("gray.50", "gray.800");
  if (colorMode === "light") toggleColorMode();

  if (!initialized) return <LoadingComponent content='Loading ...' />;

  return (
    <Box bg={bg} backgroundSize='cover'>
      <Box
        position='absolute'
        overflow='hidden'
        width='calc(100vw - (100vw - 100%))'
        minHeight='100vh'
        height='100%'
        left='0'
        top='0'
        zIndex='1'
        pointerEvents='none'
      >
        <Image
          position='absolute'
          left='30vw'
          top='80px'
          src={bgimg}
          width='100vw'
          opacity='0.05'
        />
      </Box>

      <Box zIndex='1' position='relative'>
        <ModalManager />
        <Suspense fallback={<LoadingComponentSmall content='Loading ...' />}>
          <Routes>
            {/* Pages WITHOUT Navbar */}

            <Route path='/login' element={<LoginPage />} />
            <Route path='/logout' element={<LogoutPage />} />
            <Route path='/register' element={<RegisterPage />} />
            <Route path='/recoverpassword' element={<RecoverPasswordPage />} />

            <Route path='/' element={<HomePage />} />

            {/* Pages with Navbar */}
            <Route path='/' element={<LayoutWithNavbar />}>
              {/* Private Protected Routes (Logged In Only) */}
              <Route path='/' element={<ProtectedRoutes />}>
                <Route path='masterdash' element={<MasterdashPage />} />
                <Route path={"createLobby"} element={<LobbyForm />} />
                <Route path={"editLobby/:id"} element={<LobbyForm />} />
                <Route
                  path={"previewimportquestions"}
                  element={<StreamerSettingsQuizImportPreview />}
                />
                <Route path='settings' element={<SettingsPage />}>
                  <Route path=':tabpage/:action' element={<SettingsPage />} />
                  <Route path=':tabpage' element={<SettingsPage />} />
                </Route>
                <Route path='dashboard' element={<DashboardPage />}>
                  <Route path=':url' element={<ProfilePage />} />
                </Route>
                <Route
                  path='streamersettings'
                  element={<StreamerSettingsPage />}
                >
                  <Route
                    path=':tabpage/:action'
                    element={<StreamerSettingsPage />}
                  />
                  <Route path=':tabpage' element={<StreamerSettingsPage />} />
                </Route>
              </Route>

              {/* Public Routes (Logged In + Anonymous) */}
              <Route path='help/:section' element={<About />} />
              <Route path='about/:section' element={<About />} />
              <Route path='/about' element={<About />} />
              <Route
                path='/terms-of-use'
                element={<LegalText module='terms-of-use' />}
              />
              <Route
                path='/privacy-policy'
                element={<LegalText module='privacy-policy' />}
              />
              <Route path='imprint' element={<LegalText module='imprint' />} />
              <Route path='profile'>
                <Route path=':url/:id' element={<ProfilePage />} />
                <Route path=':id' element={<ProfilePage />} />
              </Route>
              <Route path='/:url'>
                <Route path=':lobbyId' element={<StreamerUrl />} />
                <Route path='' element={<StreamerUrl />} />
              </Route>
            </Route>

            <Route component={<ErrorComponent />} />
          </Routes>
        </Suspense>

        <Footer />
        {/* <CookieConsent
          location='bottom'
          buttonText='I understand'
          declineCookieValue={false}
          cookieName='cookieConsent'
          style={{ background: "#111" }}          
          expires={150}
        >
          We use cookies to manage authentification & login. By clicking "I
          understand", you consent to our use of these technical essential
          cookies. See{" "}
          <Link as={ReactLink} to='/privacy-policy'>
            Privacy Policy
          </Link>{" "}
          for more information.
        </CookieConsent> */}
      </Box>
      {/* 
    <Route  path='/' component={HomePage} />
      <Route exact path='/login' component={LoginPage} />
      <Route
        path={"/(.+)"}
        render={() => (
          <>
            <NavBar />
            <MainLayout>
              <Route exact path='/events' component={EventDashboard} />
              <Route exact path='/sandbox' component={Sandbox} />
              <Route path='/events/:id' component={EventDetailedPage} />
              
              
              <PrivateRoute path='/profile/:id' component={ProfilePage} />
              <Route path='/error' component={ErrorComponent} />
            </MainLayout>
          </>
        )}
      /> */}
    </Box>
  );
}

export default App;
